import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(
    private router: Router,
    public http: HttpClient
    ) { }
  public callApi(
		functionUrl: string,
		requestType: string = 'GET',
		requestParams: any = {},
		urlParams: any = {},
		authRequired: boolean = true,
	): any {
		if (!functionUrl) {
			throw new TypeError('Sorry, function url is not defined.');
		}
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json;');
		let url = '';

		url = environment.apiUrl + '/' + functionUrl;

		return this.finalPart(requestType, url, requestParams, urlParams);
	}

	finalPart(requestType, functionUrl, requestParams, urlParams): Promise<any> {
		return new Promise((resolve, reject) => {
			this.callwebApi(requestType, functionUrl, requestParams, urlParams)
				.subscribe(function (res) {
					return resolve(res.body);
				}, err => {
					if (err.status == 401) {
						// localStorage.clear();
						this.router.navigate(['']);
						return reject(err);
					}
					return reject(err);
				});
		})
	}
	private callwebApi(requestType: string, functionUrl: string, requestParams: any, urlParams: any) {
		let params = new HttpParams();
		for (const i in urlParams) {
			params = params.append(i, urlParams[i]);
		}
		switch (requestType) {
			case 'GET':
				return this.http.get(functionUrl, { params: params, observe: 'response' });
			case 'POST':
				return this.http.post(functionUrl, requestParams, { params: params, observe: 'response' });
			case 'PUT':
				return this.http.put(functionUrl, requestParams, { params: params, observe: 'response' });
			case 'DELETE':
				return this.http.delete(functionUrl, { params: params, observe: 'response' });
		}
	}
}
