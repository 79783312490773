import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LocalstoregeService } from 'src/app/service/localstorege.service';
import { ApiCommonService } from 'src/app/service/api-common.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/service/common.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-header-afer-login',
  templateUrl: './header-afer-login.component.html',
  styleUrls: ['./header-afer-login.component.css']
})
export class HeaderAferLoginComponent implements OnInit {
  @Input() showBack: boolean;
  @Input() isBackConfirm: boolean;

  showLoader: boolean = false;
  constructor(
    private router: Router,
    private localStore: LocalstoregeService,
    public apiservice: ApiCommonService,
    private toastr: ToastrService,
    private commonservice: CommonService
  ) {
    this.commonservice.currentLogincheck.subscribe(res => {
      console.log('res', res);
    });
  }
  loginData: any = [];
  ngOnInit() {
    let value = this.localStore.getItem('user_data') ? JSON.parse(this.localStore.getItem('user_data')) : '';
    this.loginData = {
      email: value['email'],
      name: value['name']
    }

  }
  logOut() {
    this.showLoader = true;
    this.apiservice.logout().then(res => {
      this.showLoader = false;
      if (res.success) {
        this.localStore.removeItem('isLoggedIn');
        this.localStore.removeItem('user_data');
        this.router.navigate(['']);
        this.toastr.success(res.message, 'Success', {
          timeOut: 3000,
        });
      } else {
        this.toastr.error(res.message, 'Error', {
          timeOut: 3000,
        });
      }
    }).catch(err => {
    });
  }
  gotoUrl(str: string) {
    if (str) {
      this.router.navigate([str]);
    }
  }

  backbut() {
    console.log('isBackConfirm', this.isBackConfirm);
    if (this.isBackConfirm) {
      Swal.fire({
        title: 'Are you sure you want to exit ?',
        text: "All data will be lost",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          window.history.back();
        }
      });
      //window.history.back();
    }
    else{
      window.history.back();
    }
  }
}
