import { Injectable } from '@angular/core';

declare var localStorage: any;

@Injectable()
export class LocalstoregeService {

	constructor() { }

	clear(): void {
		localStorage.clear();
	}

	getItem(index: string): any {
		return localStorage.getItem(index);
	}

	key(key: number): string {
		return localStorage.key(key);
	}

	length(): number {
		return localStorage.length;
	}

	removeItem(index: string): void {
		localStorage.removeItem(index);
	}

	setItem(index: string, data: any): void {
		// let val = this.localStorageSpace();
		
		localStorage.setItem(index, data);
	}

	checkItem(index: string) {
		if (localStorage.getItem(index)) {
			return true;
		} 
		return false;
	}

	localStorageSpace(){
		let data = '';
		for(var key in window.localStorage){
			if(window.localStorage.hasOwnProperty(key)){
				data += window.localStorage[key];
			}
		}
		let currentSpace = ((data.length * 16)/(8 * 1024)).toFixed(2);
		let remainSpace = 5120 - parseFloat(currentSpace);
		return {
			currentSpace:currentSpace,
			remainSpace:remainSpace
		}
	}

	getBinarySize(string) {
        return parseFloat(((string.length * 16)/(8 * 1024)).toFixed(2));
    }

}



