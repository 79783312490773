import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment'

@Pipe({
   name: 'msgCustomDatePipe'
})
export class msgCustomDatePipe implements PipeTransform {
   transform(date: any) {
      //  console.log('custom pipe',date)
      //   return moment(date).calendar()
      var localTime = moment.utc(date).toDate();
      var localtime = moment(localTime).format('YYYY-MM-DD HH:mm:ss');
      var formatDate = moment(localtime).calendar();
      return (formatDate)
   }
}