import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { LocalstoregeService } from 'src/app/service/localstorege.service';
import { ApiCommonService } from 'src/app/service/api-common.service';
import { ToastrService } from 'ngx-toastr';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { Regex } from 'src/app/_constants/others.constants';
import Swal from 'sweetalert2';
import { CommonService } from 'src/app/service/common.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-form-edit',
  templateUrl: './form-edit.component.html',
  styleUrls: ['./form-edit.component.css']
})
export class FormEditComponent implements OnInit {
  id: any;
  showBack: boolean = true;
  isBackConfirm: boolean = true;
  listingData: any = [];
  imageArrayList: any[];
  netstatus: any;
  image_text: any = '';
  image_file: any = [];
  image_file_old: any;
  image_file_old_name: any;
  totalImageSize: any = 0;
  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private localStore: LocalstoregeService,
    public apiservice: ApiCommonService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private commonservice: CommonService
  ) {
    this.commonservice.currentOnlinecheck.subscribe(res => {
      if (res && res.status) {
        this.netstatus = res.status;
      }
    });
    this.id = this.route.snapshot.params.id;
  }
  onFlyImageArray: any = [];
  colorTheme = 'theme-red';
  bsConfig: Partial<BsDatepickerConfig> = { showWeekNumbers: false };
  titleCompany: any = '';
  titleOfficer: any = '';
  titleCompanyErroText: any = '';
  titleOfficerErroText: any = '';
  finalForm: any = [];
  showLoader: boolean = false;
  imageDetailsArray: any = [{
    text: "",
    file: []
  }];
  imageDetails: any = {
    text: "",
    file: []
  }
  staticImageCharacter: number = environment.staticImageCharacter;

  ngOnInit() {
    this.netstatus = this.localStore.getItem('status');
    console.log('netstatus', this.netstatus);
    this.listingData = this.localStore.getItem('listingData') ? JSON.parse(this.localStore.getItem('listingData')) : '';

    if (this.id.length == 13) {
      this.getValueFromLocal();
    } else {
      this.getValueFromAPI();
    }
    console.log('this.id', this.id.length);

  }
  public image_path_url: any;
  getValueFromAPI() {
    // throw new Error("Method not implemented.");

    this.showLoader = true;
    this.apiservice.formEdit({ 'land_id': this.id }).then(res => {
      this.showLoader = false;
      if (res.success) {
        // this.toastr.success(res.message,'', {
        //   timeOut: 3000,
        // });
        this.image_path_url = res.data.image_path;
        let unique = [...new Set(res.data.image_array.map(item => item.heading))];

        let imgArry = [];
        unique.forEach((e, i) => {
          let heading = e;
          let img = res.data.image_array.filter(function (n) {
            return n.heading === e;
          });
          imgArry.push({
            heading: heading,
            img: img
          });
        });
        this.image_text = res.data.static_image_text;
        this.image_file_old = res.data.static_image_url;
        this.image_file_old_name = res.data.static_image_name;
        this.imageArrayList = imgArry;
        this.finalForm = res.data;
        this.finalForm.fields.forEach((e, i) => {
          if (e.field_type == "date" && e.value) {
            this.finalForm.fields[i].value = new Date(e.value);
          }
          this.finalForm.fields[i]['max_length_count'] = '';
        });
      } else {
        this.toastr.error(res.message, 'Error', {
          timeOut: 3000,
        });
      }
    }).catch(err => {
      this.showLoader = false;
      console.log('err', err);

      if (err.status == 500) {
        this.toastr.error("", err.statusText, {
          timeOut: 3000,
        });
      } else {
        // this.toastr.error("", 'Maximum image size exceeded', {
        //   timeOut: 3000,
        // });
      }
    });
  }
  getValueFromLocal() {
    let listingData = this.localStore.getItem('listingData') ? JSON.parse(this.localStore.getItem('listingData')) : '';
    let val = listingData.filter(e => e.dynamic_id == this.id);
    if (val.length > 0) {
      this.finalForm = val[0];
      val[0].fields.forEach((e, i) => {
        if (e.field_type == "date" && e.value) {
          this.finalForm.fields[i].value = new Date(e.value);
        }
      });
    }
    console.log(listingData.filter(e => e.dynamic_id == this.id));
  }
  removeMoreClick(j) {
    if (this.onFlyImageArray.length > 0 && this.onFlyImageArray[j] && this.onFlyImageArray[j].length > 0 && this.onFlyImageArray[j][0] && this.onFlyImageArray[j][0]['name'] && this.onFlyImageArray[j][0]['name'] != '') {
      this.apiservice.singleDelete({ name: this.onFlyImageArray[j][0]['name'] }).then(res => {
        if (res['success']) {
          this.onFlyImageArray.splice(j, 1);
          this.toastr.success(res['message'], '', {
            timeOut: 3000,
          });
        }
      }).catch(err => {
        if (err.status == 500) {
          this.toastr.error("", err.statusText, {
            timeOut: 3000,
          });
        } else if (err.status == 401) {
          this.toastr.error("", err.error.message, {
            timeOut: 3000,
          });
        }
      });
    } else {
      this.onFlyImageArray.splice(j, 1);
    }

    this.imageDetailsArray.splice(j, 1);
  }

  addImageFileClick() {
    document.getElementById('image_file').click();
  }

  onImageFileChanged(event) {
    let textShow = 'Upload';
    switch (event.target.files.length) {
      case 0:
        textShow = 'Upload s';
        break;
      case 1:
        textShow = event.target.files.length + ' file';
        break;
      default:
        textShow = event.target.files.length + ' files';
        break;
    }
    // document.getElementById('span_image_file').innerHTML = textShow;
    // this.image_file = event.target.files;
    // this.image_file.push(event.target.files[0]);
    if (event.target.files.length > 0) {
      this.image_file.push(event.target.files[0]);

      var reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        // this.image_file_static.push({"bs64":(<FileReader>event.target).result,'name':name});
        this.image_file_static = [{ "bs64": (<FileReader>event.target).result, 'name': name }];
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  deleteActionStaticDB(path: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        console.log('path', path, this.id);
        this.showLoader = true;
        this.apiservice.staticImageDelete({
          land_id: this.id
        }).then(res => {
          this.showLoader = false;
          if (res.success) {
            this.toastr.success(res.message, '', {
              timeOut: 3000,
            });
            this.getValueFromAPI();
            this.image_file = [];
          } else {
            this.toastr.error(res.message, 'Error', {
              timeOut: 3000,
            });
          }
        }).catch(err => {
          this.showLoader = false;
        });
      }
    });

  }
  image_file_static: any = [];
  deleteActionStatic() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.image_file_static = [];
        this.image_file = [];
      }
    });
  }


  saveForm(formData,formStatus) {
    let empty = new RegExp(Regex.empty);
    let email = new RegExp(Regex.email);
    let newPhone = new RegExp(Regex.newPhone);
    let isRequiredCounter = 0
    let isRequiredCounterArr = [];

    formData.fields.forEach((e, i) => {
      if (e.required == 1) {
        if (typeof e.value == "undefined" || e.value == '') {
          isRequiredCounter = isRequiredCounter + 1;
          this.finalForm.fields[i]['showError'] = true;
          // this.toastr.error('',e.field_label+" can't be blank", {
          //     timeOut: 3000,
          //   });
        } else {
          this.finalForm.fields[i]['showError'] = false;
        }
      }
    });

    console.log('imageArrayList', this.imageArrayList);
    let imgErrprCount: any = 0;
    if (this.imageArrayList) {
      this.imageArrayList.forEach((e, i) => {
        // if(!empty.test(e.heading)){    
        if (e.heading.trim().length < 1) {
          imgErrprCount = imgErrprCount + 1;
          this.imageArrayList[i]['error'] = "Image heading is required";
        } else {
          this.imageArrayList[i]['error'] = "";
        }
      });
    }

    console.log('imageArrayList', this.imageArrayList);
    let imgErrorCountNew: any = 0;
    if (this.imageArrayList && this.netstatus == 'ONLINE') {
      this.imageDetailsArray.forEach((e, i) => {
        if (e.file.length > 0) {
          for (let index = 0; index < e.file.length; index++) {
            let txt = document.getElementById('text' + i)['value'].trim().replace(/\n/g, " ").replace(/"/g, '\'');
            // if(!empty.test(txt)){ 
            if (txt.trim().length < 1) {
              imgErrorCountNew = imgErrorCountNew + 1;
              this.imageDetailsArray[i]['error'] = "Image heading is required";
            } else {
              this.imageDetailsArray[i]['error'] = "";
            }
          }
        } else {
          this.imageDetailsArray[i]['error'] = "";
        }
      });
    }
    console.log("titleCompany length", this.finalForm.titleCompany.trim().length < 1);

    // if(!empty.test(this.finalForm.titleCompany)){    
    if (this.finalForm.titleCompany.trim().length < 1) {
      this.titleCompanyErroText = "Company title is required";
    } else {
      this.titleCompanyErroText = "";
    }
    // if(!empty.test(this.finalForm.titleOfficer)){
    if (this.finalForm.titleOfficer.trim().length < 1) {
      this.titleOfficerErroText = "Officer title is required";
    } else {
      this.titleOfficerErroText = "";
    }
    if (isRequiredCounter > 0 || this.titleOfficerErroText != '' || this.titleCompanyErroText != '' || imgErrprCount > 0 || imgErrorCountNew > 0) {
      console.log('error', imgErrprCount, imgErrorCountNew);
    } else {
      if (this.id.length == 13) {
        formData['id'] = this.id;
      } else {
        formData['land_id'] = this.id;
      }
      formData['is_published'] = formStatus == 'draft' ? 0 : 1;
      let imageText: any = [];
      if (this.netstatus == 'ONLINE') {
        this.imageDetailsArray.forEach((e, i) => {
          let val = document.getElementById('text' + i)['value'].trim().replace(/\n/g, " ").replace(/"/g, '\'');
          let count = imageText.filter(x => x == val).length;
          if (count > 0) {
            this.imageDetailsArray[i]['error'] = "Image heading can't be same"
          }
          imageText.push(val);
        });

        if (this.imageDetailsArray.filter(x => x.error != '').length > 0) {
          this.showLoader = false;
        } else {
          this.saveApiWork(formData);
        }
      } else if (this.netstatus == 'OFFLINE') {
        this.saveApiWork(formData);
      }
    }
  }

  public image_text_count: any;
  onKeyDetailsTextOne(event) {
    this.image_text_count = this.staticImageCharacter - this.image_text.length;
  }

  onKeyDetailsText(event, i: number) {
    let contSrt = document.getElementById('text' + i)['value'].length;
    this.imageDetailsArray[i]['count'] = 300 - contSrt;
  }
  onKeyFormTextSave(event, k: number) {
    this.imageArrayList[k]['count'] = 300 - this.imageArrayList[k]['heading'].length;
  }

  onKeyFormText(event, i: number) {
    if (this.finalForm.fields[i].max_length && this.finalForm.fields[i].max_length != '') {
      this.finalForm.fields[i].max_length_count = this.finalForm.fields[i].max_length - this.finalForm.fields[i].value.length;
    }
  }

  saveApiWork(formData: any) {

    let netStatus = this.localStore.getItem('status') ? this.localStore.getItem('status') : '';
    if (netStatus == 'ONLINE') {
      this.showLoader = true;
      console.log('formData', formData);
      if (formData['land_id'] == this.id) {
        this.savewithImgForm(formData);
        /*
        formData['status']='Resubmitted';
        this.apiservice.formUpdate(formData).then(res => {
          this.showLoader = false;
            if(res.success){
                this.toastr.success(res.message,'', {
                    timeOut: 3000,
                  });
            this.router.navigate(['report-list']);
            }else{
              this.toastr.error(res.message,'Error', {
                timeOut: 3000,
              });
            }
          }).catch(err => {
          this.showLoader = false;
          });
          */
      } else if (formData['dynamic_id'] == this.id) {
        formData['status'] = 'Submitted';
        console.log('asdasd', formData['dynamic_id']);
        this.fileUpload(formData);
        /*
        this.apiservice.formSave(formData).then(res => {
          this.showLoader = false;
          if(res.success){
              this.toastr.success(res.message,'', {
                  timeOut: 3000,
                });
          this.router.navigate(['report-list']);
          let listingData = this.listingData;
          let objIndex = listingData.findIndex((obj => obj.dynamic_id == formData['dynamic_id']));
          this.listingData.splice(objIndex,1);
          this.localStore.setItem('listingData',JSON.stringify(this.listingData));
          }else{
            this.toastr.error(res.message,'Error', {
              timeOut: 3000,
            });
          }
        }).catch(err => {
          this.showLoader = false;
        });
        */
      }
    } else if (netStatus == 'OFFLINE') {
      let listingData = this.localStore.getItem('listingData') ? JSON.parse(this.localStore.getItem('listingData')) : '';
      if (formData && formData.dynamic_id) {
        var objIndex = listingData.findIndex((obj => obj.dynamic_id == this.id));
        listingData[objIndex] = formData;
        this.localStore.setItem('listingData', JSON.stringify(listingData));
        this.showLoader = false;
        this.toastr.success("Data stored successfully but not synchronize", '', {
          timeOut: 3000,
        });
        this.router.navigate(['report-list']);
      } else if (formData && formData.land_id) {
        console.log('edit');
        this.toastr.error(" You are offline. Please check back when you are connected to network", 'Error', {
          timeOut: 3000,
        });
        // this.router.navigate(['report-list']);
      }
      // else if(formData && formData.land_id){
      //   formData['dynamic_id'] = formData.land_id;
      //   listingData.push([formData]);
      //   this.localStore.setItem('listingData',JSON.stringify(listingData));
      //   this.showLoader = false;
      //   this.router.navigate(['report-list']);
      // }
    }
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'dd-MMM-yyyy');
  }

  savewithImgForm(formData: any) {
    formData['status'] = 'Resubmitted';
    const param = new FormData();
    let imageDetailsText: any = [];
    this.imageDetailsArray.forEach((e, i) => {
      if (e.file.length > 0) {
        for (let index = 0; index < e.file.length; index++) {
          let txt = document.getElementById('text' + i)['value'].trim().replace(/\n/g, " ").replace(/"/g, '\'');
          imageDetailsText.push(txt);
          // let fieldsName = 'imageArray[' + txt + '--' + index + ']';
          let fieldsName = 'imageArray[' + txt + ']';

          // param.append(fieldsName, e.file[index]);
          param.append(fieldsName, e.img);
          param.append(e.img, txt);
          console.log('e.file', e.file[index]);

        }
      }
    });
    var objIndex = formData.fields.findIndex((obj => obj.field_type == "date"));
    formData.fields[objIndex]['value'] = formData.fields[objIndex]['value'] ? this.transformDate(formData.fields[objIndex]['value']) : '';


    param.append('land_id', this.id);
    param.append('imageArrayText', JSON.stringify([... new Set(imageDetailsText)]));
    param.append('imageArrayAllText', JSON.stringify(imageDetailsText));
    param.append('oldImageArray', JSON.stringify(this.imageArrayList));
    param.append('fields', JSON.stringify(formData.fields));
    param.append('status', formData.status);
    param.append('titleCompany', formData.titleCompany);
    param.append('titleOfficer', formData.titleOfficer);
    param.append('is_published', formData.is_published);
    param.append('static_image', this.image_file.length > 0 ? this.image_file[0] : '');
    param.append('static_image_old', this.image_file_old_name);
    param.append('static_image_text', this.image_text);

    const user_data: string = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : '';
    if (user_data && user_data['user_id']) {
      param.append('user_id', user_data['user_id']);
    }
    this.apiservice.formUpdateWithFile(param).then(res => {
      this.showLoader = false;
      if (res['success']) {
        this.toastr.success(res['message'], '', {
          timeOut: 3000,
        });
        this.router.navigate(['work-order-pending']);
      } else {
        this.toastr.error(res['message'], 'Error', {
          timeOut: 3000,
        });
      }
    }).catch(err => {
      this.showLoader = false;
      if (err.status == 500) {
        this.toastr.error("", err.statusText, {
          timeOut: 3000,
        });
      } else if (err.status == 401) {
        this.toastr.error("", err.error.message, {
          timeOut: 3000,
        });
        this.localStore.removeItem('user_data');
        this.localStore.removeItem('isLoggedIn');
        this.router.navigate(['']);
      }
      else {
        this.toastr.error("", 'Maximum image size exceeded', {
          timeOut: 3000,
        });
      }
    });

  }
  toggleValue(op: any, j: any, ind: any) {
    let dycls = "custom-checkbox" + j;
    let dyclsVal = document.getElementsByClassName(dycls);
    let vals = [];
    for (var i = 0, n = dyclsVal.length; i < n; i++) {
      if (dyclsVal[i]['checked']) {
        vals.push(this.finalForm.fields[ind].options[i]['value']);
        this.finalForm.fields[ind].options[i]['selected'] = 1;
      } else {
        this.finalForm.fields[ind].options[i]['selected'] = 0;
      }
    }
    this.finalForm.fields[ind].value = vals
  }

  addFileEdit(index:any){
    console.log('imageArrayList',this.imageArrayList[index]);
  }
  onFlyImageArrayEdit:any = [];
  onFileChangedEdit(event, j,k){
    let currentUploadId = this.imageArrayList[k]['img'] && this.imageArrayList[k]['img'].length > 0 && this.imageArrayList[k]['img'][0] && this.imageArrayList[k]['img'][0]['id']?this.imageArrayList[k]['img'][0]['id']:0;
    let oldImgePath = this.imageArrayList[k]['img'] && this.imageArrayList[k]['img'].length > 0 && this.imageArrayList[k]['img'][0] && this.imageArrayList[k]['img'][0]['imge_path']?this.imageArrayList[k]['img'][0]['imge_path']:'';

    console.log('imageArrayList '+k,this.imageArrayList[k]['img'][0],currentUploadId,oldImgePath);
    
    let currentFileSize: any;
    for (let i = 0; i < event.target.files.length; i++) {
      currentFileSize = ((event.target.files[i]['size'] / 1024) / 1024);
      this.totalImageSize = this.totalImageSize + currentFileSize;

    }
    console.log("mb", this.totalImageSize);


    if (this.totalImageSize < environment.maxFileLimit) {
      const param = new FormData();
      param.append('image', event.target.files[0]);
      param.append('currentUploadId', currentUploadId);
      param.append('oldImgePath', oldImgePath);
      

      const user_data: string = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : '';
      if (user_data && user_data['user_id']) {
          param.append('user_id', user_data['user_id']);
      }
      let imageData: any = [];
      this.showLoader = true;
      this.apiservice.singleImageEdit(param).then(res => {
        this.showLoader = false;

        if (res['success']) {

          let textShow = 'Upload';
          switch (event.target.files.length) {
            case 0:
              textShow = 'Upload';
              break;
            case 1:
              textShow = event.target.files.length + ' file';
              break;
            default:
              textShow = event.target.files.length + ' files';
              break;
          }
          document.getElementById('span-' + j).innerHTML = textShow;
          this.toastr.success(res['message'], '', {
            timeOut: 3000,
          });

          imageData = res['data'];

          this.onFlyImageArrayEdit[j] = Array();
          for (let i = 0; i < event.target.files.length; i++) {
            // this.imageDetailsArray[j].file.push(event.target.files[i]);
            // this.imageDetailsArray[j].img = imageData && imageData.name ? imageData.name : '';
            let name = event.target.files[i]['name'];
            var reader = new FileReader();
            reader.onload = (event: ProgressEvent) => {
              this.onFlyImageArrayEdit[j].push({ "bs64": (<FileReader>event.target).result, 'name': name });
              // this.imageDetailsArray[j].img = imageData && imageData.name ? imageData.name : '';
            }
            reader.readAsDataURL(event.target.files[i]);
          }
        } else {
          this.toastr.error(res['message'], 'Error', {
            timeOut: 3000,
          });
        }
      }).catch(err => {
        this.showLoader = false;
        if (err.status == 500) {
          this.toastr.error("", err.statusText, {
            timeOut: 3000,
          });
        } 
        else if(err.status == 400){

          this.toastr.error("", err.error.message, {
            timeOut: 3000,
          });
        } 
        
        else if (err.status == 401) {
          this.toastr.error("", err.error.message, {
            timeOut: 3000,
          });
          this.localStore.removeItem('user_data');
          this.localStore.removeItem('isLoggedIn');
          this.router.navigate(['']);
        }
      });

    } else {
      this.totalImageSize = this.totalImageSize - currentFileSize;
      this.toastr.error("", 'Maximum image size exceeded', {
        timeOut: 3000,
      });
    }
    
  }

  deleteAction(id: any) {
    console.log('deleteAction -> id', id);
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        //go for delet api
        this.apiservice.imageDelete({ 'id': id }).then(res => {
          this.showLoader = false;
          if (res.success) {
            this.toastr.success(res.message, '', {
              timeOut: 3000,
            });
            this.getValueFromAPI();
          } else {
            this.toastr.error(res.message, 'Error', {
              timeOut: 3000,
            });
          }
        }).catch(err => {
          this.showLoader = false;
        });
      }
    })
  }
  onTextChanged(event, index) {
    console.log('event', event.target);
  }

  onFileChanged(event, j) {
    let currentFileSize: any;
    for (let i = 0; i < event.target.files.length; i++) {
      currentFileSize = ((event.target.files[i]['size'] / 1024) / 1024);
      this.totalImageSize = this.totalImageSize + currentFileSize;

    }
    console.log("mb", this.totalImageSize);


    if (this.totalImageSize < environment.maxFileLimit) {

      // this.imageDetailsArray[j].file = event.target.files;
      /*********  new code start  ********/

      const param = new FormData();
      param.append('image', event.target.files[0]);
      const user_data: string = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : '';
      if (user_data && user_data['user_id']) {
          param.append('user_id', user_data['user_id']);
      }
      let imageData: any = [];
      this.showLoader = true;
      this.apiservice.singleUpload(param).then(res => {
        this.showLoader = false;

        if (res['success']) {

          let textShow = 'Upload';
          switch (event.target.files.length) {
            case 0:
              textShow = 'Upload';
              break;
            case 1:
              textShow = event.target.files.length + ' file';
              break;
            default:
              textShow = event.target.files.length + ' files';
              break;
          }
          document.getElementById('span-' + j).innerHTML = textShow;




          this.toastr.success(res['message'], '', {
            timeOut: 3000,
          });

          imageData = res['data'];

          this.onFlyImageArray[j] = Array();
          for (let i = 0; i < event.target.files.length; i++) {
            this.imageDetailsArray[j].file.push(event.target.files[i]);
            this.imageDetailsArray[j].img = imageData && imageData.name ? imageData.name : '';
            let name = event.target.files[i]['name'];
            var reader = new FileReader();
            reader.onload = (event: ProgressEvent) => {
              this.onFlyImageArray[j].push({ "bs64": (<FileReader>event.target).result, 'name': name });
              this.imageDetailsArray[j].img = imageData && imageData.name ? imageData.name : '';
            }
            reader.readAsDataURL(event.target.files[i]);
          }
          // this.router.navigate(['report-list']);
        } else {
          this.toastr.error(res['message'], 'Error', {
            timeOut: 3000,
          });
        }
      }).catch(err => {
        this.showLoader = false;
        if (err.status == 500) {
          this.toastr.error("", err.statusText, {
            timeOut: 3000,
          });
        } 
        else if(err.status == 400){

          this.toastr.error("", err.error.message, {
            timeOut: 3000,
          });
        } 
        
        else if (err.status == 401) {
          this.toastr.error("", err.error.message, {
            timeOut: 3000,
          });
          this.localStore.removeItem('user_data');
          this.localStore.removeItem('isLoggedIn');
          this.router.navigate(['']);
        }
      });


      /*********  new code end  ********/


    } else {
      this.totalImageSize = this.totalImageSize - currentFileSize;
      this.toastr.error("", 'Maximum image size exceeded', {
        timeOut: 3000,
      });
    }
  }

  deleteActionObFly(k: number, j: number) {
    console.log('deleteAction', this.imageDetailsArray[j].img);
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {

        this.showLoader = true;

        this.apiservice.singleDelete({ name: this.imageDetailsArray[j].img }).then(res => {
          this.showLoader = false;
          if (res['success']) {
            this.toastr.success(res['message'], '', {
              timeOut: 3000,
            });
            for (let q = 0; q < this.imageDetailsArray[j]['file'].length; q++) {
              if (this.imageDetailsArray[j]['file'][q]['name'] == this.onFlyImageArray[j][k]['name']) {
                let rtn = this.imageDetailsArray[j]['file'].splice(q, 1);
                if (rtn) {
                  this.onFlyImageArray[j].splice(k, 1);
                  /********/
                  let textId = 'span-' + j;
                  let textShow = '';
                  switch (this.imageDetailsArray[j]['file'].length) {
                    case 0:
                      textShow = 'Upload';
                      break;
                    case 1:
                      textShow = this.imageDetailsArray[j]['file'].length + ' file';
                      break;
                    default:
                      textShow = this.imageDetailsArray[j]['file'].length + ' files';
                      break;
                  }
                  document.getElementById(textId).innerHTML = textShow;
                  /*********/
                }
              }
            }
          }

        }).catch(err => {
          this.showLoader = false;
          if (err.status == 500) {
            this.toastr.error("", err.statusText, {
              timeOut: 3000,
            });
          } else if (err.status == 401) {
            this.toastr.error("", err.error.message, {
              timeOut: 3000,
            });
          }
        });
      }
    });

  }
  addMoreClick() {
    this.imageDetailsArray.push({
      text: "",
      file: []
    });
  }
  addFileClick(j) {
    let nameFile = 'file' + j;
    document.getElementById(nameFile).click();
  }

  fileUpload(formData) {

    // formData['titleCompany'] = this.titleCompany;
    // formData['titleOfficer'] =this.titleOfficer;

    const param = new FormData();
    let imageDetailsText: any = [];
    this.imageDetailsArray.forEach((e, i) => {
      if (e.file.length > 0) {
        for (let index = 0; index < e.file.length; index++) {
          let txt = document.getElementById('text' + i)['value'].trim().replace(/\n/g, " ").replace(/"/g, '\'');
          imageDetailsText.push(txt);
          let fieldsName = 'imageArray[' + txt + '--' + index + ']';
          param.append(fieldsName, e.file[index]);
        }
      }
    });

    param.append('imageArrayText', JSON.stringify([... new Set(imageDetailsText)]));
    param.append('imageArrayAllText', JSON.stringify(imageDetailsText));
    param.append('fields', JSON.stringify(formData.fields));
    param.append('status', formData.status);
    param.append('titleCompany', formData.titleCompany);
    param.append('titleOfficer', formData.titleOfficer);
    param.append('is_published', formData.is_published);
    param.append('static_image', this.image_file.length > 0 ? this.image_file[0] : '');
    param.append('static_image_text', this.image_text);

    const user_data: string = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : '';
    if (user_data && user_data['user_id']) {
      param.append('user_id', user_data['user_id']);
    }
    this.apiservice.formSaveWithFile(param).then(res => {
      this.showLoader = false;
      if (res['success']) {
        this.toastr.success(res['message'], '', {
          timeOut: 3000,
        });
        let listingData = this.listingData;
        let objIndex = listingData.findIndex((obj => obj.dynamic_id == formData['dynamic_id']));
        this.listingData.splice(objIndex, 1);
        this.localStore.setItem('listingData', JSON.stringify(this.listingData));
        this.router.navigate(['report-list']);
      } else {
        this.toastr.error(res['message'], 'Error', {
          timeOut: 3000,
        });
      }
    }).catch(err => {
      this.showLoader = false;
      if (err.status == 500) {
        this.toastr.error("", err.statusText, {
          timeOut: 3000,
        });
      } else {
        this.toastr.error("", 'Maximum image size exceeded', {
          timeOut: 3000,
        });
      }
    });

  }
  backbut() {
    // window.history.back();
    Swal.fire({
        title: 'Are you sure you want to exit ?',
        text: "All data will be lost",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    }).then((result) => {
        if (result.value) {
          window.history.back();
        }
    });
  }

}
