import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalstoregeService } from './../../service/localstorege.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiCommonService } from "./../../service/api-common.service";
import { ToastrService } from 'ngx-toastr';
import { Regex } from 'src/app/_constants/others.constants';
import { CommonService } from 'src/app/service/common.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private router:Router,
    private localStore:LocalstoregeService,
    public apiservice:ApiCommonService,
    private toastr: ToastrService,
    private commonservice: CommonService
  ) { }
  public loginForm: FormGroup;
  public invalidloginForm: boolean = false;
  showLoader:boolean = false;

  ngOnInit() {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required, this.emptyValidator.bind(this), Validators.pattern(Regex.email)]),
      password: new FormControl('', [Validators.required, this.emptyValidator.bind(this)]),
    });
  }
  private emptyValidator(control: any) {
    if (this.invalidloginForm) {
      if (control.value.trim() == '') {
        return { empty: true };
      }
      return null;
    } else {
      return null;
    }
  }
  loginFormSubmit(forms:FormGroup){
    if (forms.valid) {
      this.invalidloginForm = false;
      this.doLoginApi(forms);
      // this.gotoURL('report-list');
    } else {
      this.invalidloginForm = true;
    }
  }
  doLoginApi(forms: FormGroup) {
    this.showLoader = true;
    let param = { email: forms.value.username, password: forms.value. password};
    // let param = { email: "somnath.mukherjee@dreamztech.com", password: "12345678"}
    this.apiservice.login(param).then(res => {
      this.showLoader = false;
      if(res.success){
        this.loginForm.reset();
        this.localStore.setItem('user_data',JSON.stringify(res.data));
        this.localStore.setItem("isLoggedIn","y");
        this.commonservice.setLogincheck(JSON.stringify(res.data));
        // this.toastr.success(res.message, 'Success', {
        //   timeOut: 3000,
        // });
        // this.gotoURL('report-list');
        this.gotoURL('work-order-pending');
      }else{
        this.toastr.error(res.message,'Error', {
          timeOut: 3000,
        });
      }
    }).catch(err => {
      this.showLoader = false;
    });
  }
  gotoURL(str:string){
    if(str){
      this.router.navigate([str]);
    }
  }
}
