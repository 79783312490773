import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiCommonService } from 'src/app/service/api-common.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-work-order',
  templateUrl: './view-work-order.component.html',
  styleUrls: ['./view-work-order.component.css']
})
export class ViewWorkOrderComponent implements OnInit {
  id: any;
  showLoader: boolean = false;
  workOrderData: any = {};
  showToast: boolean = false;
  propertyData: any = [];
  serviceData: any = [];
  showBack: boolean = true;
  isBackConfirm: boolean = false;
  constructor(
    public apiservice: ApiCommonService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {

    this.id = this.route.snapshot.params.id;
  }

  propertyInspection = [
    { id: 1, name: "ALTA", value: "alta", description: "Includes the following:address verification, possession, improvements,visual easement check encroachment check,ingress/egress, structures, unrecorded leases,evidence of work started" },
    { id: 2, name: "Foundation Inspection 102.4", value: "foundation-inspection-102.4", description: "" },
    { id: 3, name: "Foundation Endorsement 102.5 (+$10)", value: "foundation-endorsement-102.5-(+$10)", description: "Please provide survey and commitment" },
    { id: 4, name: "Completion Check", value: "completion-check", description: "" },
    { id: 5, name: "Priority Inspection (Mechanic's Lien)", value: "priority-inspection-(mechanic's-lien)", description: "" },
  ];
  serviceType = [
    { id: 1, name: "STANDARD", value: "standard", description: "three business days" },
    { id: 2, name: "RUSH (+$10)", value: "rush-(+$10)", description: "Completed next day by 11am" },
    { id: 3, name: "SUPER RUSH (+$20)", value: "super-rush-($20)", description: "Same-day if requested by 2pm, otherwise this become a rush order." },
    { id: 4, name: "INSPECTION ON DAY OF CLOSE", value: "inspection-on-day-of-close", description: "Completed by noon on COE date" },
  ];


  ngOnInit() {
    this.showToast = false;
    this.getWorkOrderDetails();
  }

  //API call
  getWorkOrderDetails() {
    console.log('id', this.id);
    let params = {
      list: 1,
      // status: 2,
      id: this.id
    }
    this.apiservice.getWorkOrderListWithDetails(params).then(res => {
      console.log('res==>', res)
      if (res.success) {
        this.workOrderData = res.data && res.data.length && res.data[0] && res.data[0].data && res.data[0].data.length && res.data[0].data[0] ? res.data[0].data[0] : null;

        if (this.workOrderData && this.workOrderData.property_inspection &&
          this.workOrderData.property_inspection.length) {
          var selectedData = [];
          this.workOrderData.property_inspection.map(d => {
            var sd = this.propertyInspection.filter(p => p.id == d)
            if (sd.length) {
              var obj = { name: sd[0].name, des: sd[0].description };
              selectedData.push(obj)
            }
          })
          this.propertyData = selectedData;
        }

        if (this.workOrderData && this.workOrderData.service_type &&
          this.workOrderData.service_type.length) {
          var selectedData = [];
          this.workOrderData.service_type.map(d => {
            var sd = this.serviceType.filter(p => p.id == d)
            if (sd.length) {
              var colorClass = 'name';
              var desColorClass = 'des';
              if (sd[0].id == 2) {
                colorClass = 'name rush';
                desColorClass = 'des rush'
              }
              if (sd[0].id == 3) {
                colorClass = 'name super-rush';
                desColorClass = 'des super-rush'
              }
              var obj = { name: sd[0].name, des: sd[0].description, colorclass: colorClass, descolorclass: desColorClass };
              selectedData.push(obj)
            }
          })
          this.serviceData = selectedData;
        }
      } else {
        if (this.showToast) {
          this.showToast = false;
          this.toastr.error(res.message, 'Error', {
            timeOut: 3000,
          });
        }
      }
    }).catch(err => {
      this.showLoader = false;

    });

  }

  printWorkOrder() {
    // var printContents = document.getElementById('printwoform').innerHTML;
    // var originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    // window.print();
    // document.body.innerHTML = originalContents;
    var printContents = document.getElementById('printwoform').innerHTML;
    var mywindow = window.open('', 'new div', 'height=400,width=600');
    mywindow.document.write('<html><head><title></title>');
    mywindow.document.write('<link rel="stylesheet" href=" https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" type="text/css" />');
    mywindow.document.write('<link rel="stylesheet" href="assets/css/_custom.css" type="text/css" />');
    mywindow.document.write('</head><body style="background-color:white">');
    mywindow.document.write(printContents);
    mywindow.document.write('</body></html>');
    mywindow.document.close();
    mywindow.focus();
    // setTimeout(()=> { mywindow.print(); }, 2000);
    setTimeout(() => {
      mywindow.print();
    }, 2000);
  }

  // ngAfterViewChecked() {
  //   window.onafterprint = () => {
  //     window.location.reload(true);

  //   }
  // }

  printFileAttached(url) {
    console.log('url==', url);
    if (url.substr(url.lastIndexOf('.') + 1) == 'pdf') {
      console.log('pdf print');
      var mywindow = window.open('', 'new div', 'height=400,width=600');
      mywindow.document.write('<html><head><title></title>');
      mywindow.document.write('</head><body style="background-color:white">');
      mywindow.document.write('<object data="' + url + '" type="application/pdf" width="600" height="400"></object>');
      mywindow.document.write('</body></html>');
      mywindow.document.close();
      mywindow.focus();
      setTimeout(() => {
        mywindow.print();
      }, 1000);
    } else {
      console.log('image print');
      var w = window.open('', 'new div', 'height=400,width=600');
      w.document.write('<html><head>');
      w.document.write('</head><body >');
      w.document.write('<img src="' + url + '"/>');
      w.document.write('</body></html>');
      // w.window.print();
      w.document.close();
      w.focus();
      setTimeout(() => {
        w.print();
      }, 500);
    }
  }

}
