import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'
const user_data: string = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : '';
const httpOptions = {
  headers: new HttpHeaders({ 'Authorization': 'Bearer ' + user_data['token'] })
};
@Injectable({
  providedIn: 'root'
})

export class ApiCommonService {

  // constructor(
  //   public service: BaseService
  //   ) { 
  // }

  private httpClient: HttpClient;

  constructor(
    private http: HttpClient,
    public service: BaseService,
    handler: HttpBackend
  ) {
    this.httpClient = new HttpClient(handler);
  }

  login(data) {
    return this.service.callApi('login', 'POST', data);
  }

  logout() {
    return this.service.callApi('logout', 'POST', {});
  }
  getLandList(data) {
    return this.service.callApi('land/list', 'GET', {}, data);
  }

  resetPass(data) {
    return this.service.callApi('user-password-update', 'POST', data);
  }

  forgotPass(data) {
    return this.service.callApi('get-reset-token', 'POST', data);
  }

  getFormDetails() {
    return this.service.callApi('inspection/form', 'GET');
  }

  singleDelete(data) {
    return this.service.callApi('single/delete', 'POST', data);
  }
  singleImageEdit(data) {
    return this.service.callApi('single/image/edit', 'POST', data);
  }
  // formSave(data) {
  //   return this.service.callApi('customer/signin', 'POST',data);
  // }

  formSaveWithFile(data) {
    const user_data: string = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : '';
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + user_data['token'] })
    };
    console.log('httpOptions', httpOptions);
    return this.httpClient.post(environment.apiUrl + '/land/iamage/upload', data, httpOptions).toPromise();
  }

  formUpdateWithFile(data) {
    const user_data: string = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : '';
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + user_data['token'] })
    };
    console.log('httpOptions', httpOptions);

    return this.httpClient.post(environment.apiUrl + '/land/image/update', data, httpOptions).toPromise();
  }
  singleUpload(data) {
    const user_data: string = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : '';
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + user_data['token'] })
    };
    console.log('httpOptions', httpOptions);

    return this.httpClient.post(environment.apiUrl + '/single/upload', data, httpOptions).toPromise();
  }

  fileSendOverMsg(data) {
    const user_data: string = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : '';
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + user_data['token'] })
    };
    console.log('httpOptions', httpOptions);

    return this.httpClient.post(environment.apiUrl + '/send/chat/file', data, httpOptions).toPromise();
  }


  formEdit(data) {
    return this.service.callApi('land/edit/image', 'POST', data);
  }
  imageDelete(data) {
    return this.service.callApi('land/image/delete', 'POST', data);
  }
  staticImageDelete(data) {
    return this.service.callApi('static/image/delete', 'POST', data);
  }
  formUpdate(data) {
    return this.service.callApi('land/update', 'POST', data);
    // return this.httpClient.post(environment.apiUrl + '/land/update', data, httpOptions).toPromise();
  }

  bulkUpload(data) {
    return this.service.callApi('bulk/upload', 'POST', data);
  }
  exportpdf(data) {
    return this.service.callApi('export/pdf', 'POST', data);
  }

  //new Api implement

  getNumberOfPendingCompleteCount(data) {
    return this.service.callApi('inspector/orders/count', 'GET', {}, data);
  }

  getWorkOrderListWithDetails(data) {
    return this.service.callApi('inspector/orders', 'GET', {}, data);
  }

  //message related api
  getChatFriendList(data) {
    return this.service.callApi('my/chat/list', 'GET', {}, data);
  }
  getParticularFriendChatDetails(data) {
    return this.service.callApi('get/chat/details', 'GET', {}, data);
  }
  saveOrSendMsg(data) {
    return this.service.callApi('send/chat', 'POST', data);
  }



}
