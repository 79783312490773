import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiCommonService } from 'src/app/service/api-common.service';
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  showLoader: boolean = false;
  friendList: any = [];
  messageDetails: any = [];
  chatMsg: String = '';
  meId: number;
  orderId: any;
  friendId: any;
  showTypeMsg: boolean = false;
  public current_page: number = 1;
  showBack: boolean = true;
  isBackConfirm: boolean = false;
  constructor(
    public apiservice: ApiCommonService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.loadFriendList()
  }
  loadFriendList() {
    this.showLoader = true;
    let params = {}
    this.apiservice.getChatFriendList(params).then(res => {
      // console.log('res==>', res)
      if (res.success) {
        this.showLoader = false;
        this.friendList = res.data && res.data.length ? res.data : []
        this.meId = res.me ? res.me : null
        // console.log('this.friendList',this.friendList)
      } else {
        // if(this.showToast){
        //   this.showToast = false;
        //   this.toastr.error(res.message,'Error', {
        //     timeOut: 3000,
        //   });
        // }
      }
    }).catch(err => {
      this.showLoader = false;

    });
  }

  loadChatDetails(friend, fromWhere) {
    console.log('load particular chat details', friend);
    console.log('logedin UserId', this.meId);
    this.orderId = friend.order_id;
    if (fromWhere == 'leftPanel') {
      this.current_page = 1;
      this.messageDetails = [];
    }
    this.showLoader = true;
    this.friendId = friend.id;
    this.showTypeMsg = true;
    let params = {
      me_user: this.meId ? this.meId : null,
      friend_user: friend.id,
      order_id: friend.order_id,
      page: this.current_page
    }
    this.apiservice.getParticularFriendChatDetails(params).then(res => {
      console.log('res==>', res)
      this.showLoader = false;
      if (res.success) {
        if (!this.messageDetails.length) {
          this.messageDetails = res.data && res.data.length && res.data[0] && res.data[0].data && res.data[0].data.length ? res.data[0].data.reverse() : [];

          console.log('this.messageDetails', this.messageDetails)
          setTimeout(() => {
            var objDiv = document.getElementById("cmessages");
            console.log('scrollHeight', objDiv.scrollHeight)
            objDiv.scrollTop = objDiv.scrollTop + objDiv.scrollHeight;
          }, 200);
        }
        else {
          console.log('unshift here');
          var data = res.data[0].data;
          data.forEach(item => {
            this.messageDetails.unshift(item);
          })
        }

        console.log('outside this.messageDetails', this.messageDetails)

      } else {
        // if(this.showToast){
        //   this.showToast = false;
        //   this.toastr.error(res.message,'Error', {
        //     timeOut: 3000,
        //   });
        // }
      }
    }).catch(err => {
      this.showLoader = false;

    });
  }

  onScroll() {
    console.log('scrolled up called', this.orderId);
    this.current_page = this.current_page + 1;
    let friend = {
      id: this.friendId,
      order_id: this.orderId ? this.orderId : null,
    }
    this.loadChatDetails(friend, 'onscroll')
  }

  sendChat() {
    this.showLoader = true;
    console.log('sendChat', this.chatMsg);
    let params = {
      sent_by: this.meId,
      sent_to: this.friendId,
      order_id: this.orderId ? this.orderId : null,
      message: this.chatMsg,
    }
    this.apiservice.saveOrSendMsg(params).then(res => {
      this.showLoader = false;
      if (res.success) {
        let friend = {
          id: this.friendId,
          order_id: this.orderId ? this.orderId : null,
        }
        this.current_page = 1;
        this.messageDetails = [];
        this.loadChatDetails(friend, 'sendButtonPress')
        this.chatMsg = '';
      } else {

        // if(this.showToast){
        //   this.showToast = false;
        //   this.toastr.error(res.message,'Error', {
        //     timeOut: 3000,
        //   });
        // }
      }
    }).catch(err => {
      this.showLoader = false;

    });
  }

  onKeypressEvent(e: any) {
    // console.log('keypress event',e.target.value);
    if (e.keyCode == 13 && e.shiftKey == false) {
      // console.log('enter key press in text box');
      this.sendChat()
    }
  }
  addImageFileClick() {
    document.getElementById('image_file').click();
  }
  onImageFileChanged(event) {
    console.log('file upload event ',event.target.files[0]);
    const param = new FormData();
    param.append('sent_to',this.friendId);
    param.append('order_id',this.orderId ? this.orderId : null);
    param.append('files[0]', event.target.files[0]);
    
    this.showLoader = true;
    this.apiservice.fileSendOverMsg(param).then(res => {
        this.showLoader = false;
        let friend = {
          id: this.friendId,
          order_id: this.orderId ? this.orderId : null,
        }
        this.current_page = 1;
        this.messageDetails = [];
        this.loadChatDetails(friend, 'sendButtonPress')

    }).catch(err => {
        this.showLoader = false;
       
    });


   
  }

}
