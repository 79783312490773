import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { HeaderAferLoginComponent } from './header-afer-login/header-afer-login.component';
import { FooterAferLoginComponent } from './footer-afer-login/footer-afer-login.component';
import { HeaderBeforeLoginComponent } from './header-before-login/header-before-login.component';
import { FooterBeforeLoginComponent } from './footer-before-login/footer-before-login.component';


@NgModule({
  declarations: [
    // HeaderComponent,
    // FooterComponent,

    HeaderAferLoginComponent,
    FooterAferLoginComponent,
    HeaderBeforeLoginComponent,
    FooterBeforeLoginComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports:[
    HeaderAferLoginComponent,
    FooterAferLoginComponent,
    HeaderBeforeLoginComponent,
    FooterBeforeLoginComponent
  ]
})
export class ShareModule { }
