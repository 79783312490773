// import { Component } from '@angular/core';

// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.css']
// })
// export class AppComponent {
//   title = 'HeatherCooper';
// }


import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ConnectionService } from 'ng-connection-service';
import { CommonService } from './service/common.service';
import { ApiCommonService } from './service/api-common.service';
import { LocalstoregeService } from './service/localstorege.service';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Heather Cooper';
  status = '';
  isConnected = true;
  deviceInfo:any = null;
  isShowAddToHomeCustom:boolean = false;
  constructor(
    private update: SwUpdate,
    private commonservice:CommonService,
    private connectionService: ConnectionService,
    public apiservice:ApiCommonService,
    private localStore:LocalstoregeService,
    private deviceService: DeviceDetectorService
  ) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
      }
      else {
        this.status = "OFFLINE";
      }
      this.localStore.setItem('status',this.status);
      this.commonservice.setOnlinecheck({'status':this.status});
    })
  }
  iOS:any = ['IOS','iOS','ios','Mac','MAC','mac']

  ngOnInit(): void {
    console.log('online check',navigator.onLine);
    if(navigator.onLine){
      this.status = "ONLINE";
    }else {
      this.status = "OFFLINE";
    }
    this.localStore.setItem('status',this.status);
    this.commonservice.setOnlinecheck({'status':this.status});

    if (this.update.isEnabled) {
      this.update.available.subscribe(() => {
        if (confirm("New version available. Load New Version?")) {
          window.location.reload();
        }
      });
    }
    this.deviceInfo = this.deviceService.getDeviceInfo();
      const isMobile = this.deviceService.isMobile();
      const isTablet = this.deviceService.isTablet();
      const isDesktopDevice = this.deviceService.isDesktop();
      let tapToIOsHome = this.localStore.getItem('tapToIOsHome')?this.localStore.getItem('tapToIOsHome'):'';
      console.log('tapToIOsHome',tapToIOsHome);
      
      if(tapToIOsHome == '' && this.iOS.includes(this.deviceInfo.os) && (isMobile || isTablet) && !isDesktopDevice){
        this.isShowAddToHomeCustom = true;
      }
      console.log("Hello browser",this.deviceInfo.browser,'os',this.deviceInfo.os,'isShowAddToHomeCustom',this.isShowAddToHomeCustom);
      console.log("deviceInfo",this.deviceInfo);
      console.log("isMobile",isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
      console.log("isTablet",isTablet);  // returns if the device us a tablet (iPad etc)
      console.log("isDesktopDevice",isDesktopDevice); 
  }
  tapToHome(){
    this.localStore.setItem('tapToIOsHome','true');
    this.isShowAddToHomeCustom = false;
  }
}
