import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BaseService } from './service/base.service';
import { ApiCommonService } from './service/api-common.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpConfigInterceptor } from './iterceptor/httpconfig.interceptor';
import { AuthGuard, BeforeAuthGuard, AppAuthGuard } from "./share/_guards/index";
import { CommonService } from './service/common.service';
import { UserModule } from './user/user.module';
import { LocalstoregeService } from './service/localstorege.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from  'ngx-ui-loader';
import { DatePipe } from '@angular/common';
import { ClipboardModule } from 'ngx-clipboard';
import { DeviceDetectorModule } from 'ngx-device-detector';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UserModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgxUiLoaderModule, 
    NgxUiLoaderRouterModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    DeviceDetectorModule.forRoot(),
    ClipboardModule
  ],
  providers: [
    DatePipe,
    AuthGuard,
    BeforeAuthGuard,
    AppAuthGuard,
    BaseService,
    CommonService,
    ApiCommonService,
    LocalstoregeService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
