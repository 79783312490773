import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LocalstoregeService } from 'src/app/service/localstorege.service';
import { ApiCommonService } from 'src/app/service/api-common.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  token: any;

  constructor(
    private router:Router,
    private route: ActivatedRoute,
    private localStore:LocalstoregeService,
    public apiservice:ApiCommonService,
    private toastr: ToastrService
    ) { }
    public resetPassForm: FormGroup;
    showLoader:boolean = false;
    public invalidresetPassForm: boolean = false;
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
    });
    this.resetPassForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(6),Validators.maxLength(16),this.validatePassword.bind(this)]),
      confirmpassword: new FormControl('', [Validators.required, this.validatePassword.bind(this),this.confirmpasswordcheck.bind(this)]),
    });
  }
  private validatePassword(control: FormControl): any {
    if (this.resetPassForm && control.value) {
      return control.value.trim() === '' ? { emptyValue: true } : null;
    }
  }
  private confirmpasswordcheck(control: FormControl): any {
    if (this.resetPassForm && control.value) {
      return control.value === this.resetPassForm.get('password').value ? null : { notSame: true }
    }
  }
  resetPassFormSubmit(forms:FormGroup){
    if (forms.valid) {
      this.invalidresetPassForm = false;
      this.doResetApi(forms);
      // this.gotoURL('login');
    } else {
      this.invalidresetPassForm = true;
    }
    console.log("resetPassFormSubmit",forms,this.resetPassForm);
  }

  doResetApi(forms: FormGroup) {
    this.showLoader = true;
    let param = { confirm_password: forms.value.confirmpassword, password: forms.value. password,token:this.token};
    console.log('doResetApi',param);
    this.apiservice.resetPass(param).then(res => {
      this.showLoader = false;
      if(res.success){
        this.toastr.success(res.message, 'Success', {
          timeOut: 3000,
        });
        this.resetPassForm.reset();
        this.gotoURL('');
      }else{
        this.toastr.error(res.message,'Error', {
          timeOut: 3000,
        });
      }
    }).catch(err => {
      this.showLoader = false;
    });
  }
  gotoURL(str:string){
    this.router.navigate(['']);
  }
}
