import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: "root"
})
export class AuthGuard implements CanActivate {

	constructor(private router: Router) { }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		let currentRoute = next.routeConfig.path;

		if (localStorage.getItem('isLoggedIn') && localStorage.getItem('isLoggedIn') == "y") {

			return true;
		} else {
			this.router.navigate(['']);
			return false;
		}
	}
}

@Injectable()
export class AppAuthGuard implements CanActivate {

	constructor(private router: Router) { }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		let currentRoute = next.routeConfig.path;
		let applist = localStorage.getItem('user-app') ? JSON.parse(localStorage.getItem('user-app')) : null;
		let userAppList = applist.map((val, i, arr) => { return { id: val.app.id, name: val.app.name, key: val.app.key }; });
		let productKay = currentRoute.split("/")[1];
		let straingData = userAppList.filter(val => {
			return val.key == productKay
		});
		console.log('straingData',straingData);
		
		if (straingData.length > 0) {
			return true;
		} else {
			this.router.navigate(['store']);
			return false;
		}
	}
}

@Injectable()
export class BeforeAuthGuard implements CanActivate {

	constructor(
		private router: Router,
	) {

	}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		let isLoggedIn = localStorage.getItem('isLoggedIn');
		let currentRoute = next.routeConfig.path;
		if (isLoggedIn) {
			// this.router.navigate(['report-list']);
			this.router.navigate(['work-order-pending']);
		}
		return true;

	}
}