import { Component, OnInit } from '@angular/core';
import { LocalstoregeService } from 'src/app/service/localstorege.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-testpdf',
  templateUrl: './testpdf.component.html',
  styleUrls: ['./testpdf.component.css']
})
export class TestpdfComponent implements OnInit {

  localhrml:any;
  // abcd = "http://www.africau.edu/images/default/sample.pdf";
  abcd = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  pdfSrcData:any = [];
  constructor(
    private localStore:LocalstoregeService,
    private route: ActivatedRoute,
  ) { 
    
    // this.abcd = this.route.snapshot.params.url;
  }

  ngOnInit() {
    
    this.pdfSrcData = this.localStore.getItem('pdf_Data')?JSON.parse(this.localStore.getItem('pdf_Data')):[];
    if(this.pdfSrcData && this.pdfSrcData.static_url && this.pdfSrcData.static_url!=''){
      this.abcd = this.pdfSrcData.static_url;
    }
    console.log('localhrml',this.pdfSrcData && this.abcd);
    
  }

}
