import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { LoginComponent } from './user/login/login.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { RecordListingComponent } from './user/record-listing/record-listing.component';
import { BeforeAuthGuard, AuthGuard } from './share/_guards';
import { ForgetPasswordComponent } from './user/forget-password/forget-password.component';
import { FormAddComponent } from './user/form-add/form-add.component';
import { FormEditComponent } from './user/form-edit/form-edit.component';
import { TestpdfComponent } from './user/testpdf/testpdf.component';
import { WorkOrderComponent } from './user/work-order/work-order.component';
import { WorkOrderCompletedComponent } from './user/work-order-completed/work-order-completed.component';
import { ViewWorkOrderComponent } from './user/view-work-order/view-work-order.component';
import { MessageComponent } from './user/message/message.component';
import { InstantmsgComponent } from './user/instantmsg/instantmsg.component';

const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [BeforeAuthGuard] },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [BeforeAuthGuard] },
  { path: 'forgot-password', component: ForgetPasswordComponent, canActivate: [BeforeAuthGuard] },
  { path: 'report-list', component: RecordListingComponent, canActivate: [AuthGuard] },
  { path: 'add-report', component: FormAddComponent, canActivate: [AuthGuard] },
  { path: 'add-report/:id', component: FormAddComponent, canActivate: [AuthGuard] },
  { path: 'edit-report/:id', component: FormEditComponent, canActivate: [AuthGuard] },
  { path: 'pdf', component: TestpdfComponent, canActivate: [AuthGuard] },
  { path: 'work-order-pending', component: WorkOrderComponent, canActivate: [AuthGuard] },
  { path: 'work-order-complete', component: WorkOrderCompletedComponent, canActivate: [AuthGuard] },
  { path: 'view-work-order/:id', component: ViewWorkOrderComponent, canActivate: [AuthGuard] },
  { path: 'message', component: MessageComponent, canActivate: [AuthGuard] },
  { path: 'workorder-message/:wid/:uid/:frndid', component: InstantmsgComponent, canActivate: [AuthGuard] }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.errorHandler = (error: any) => {
      this.router.navigate(['']);
    }
  }
}
