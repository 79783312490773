import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-before-login',
  templateUrl: './footer-before-login.component.html',
  styleUrls: ['./footer-before-login.component.css']
})
export class FooterBeforeLoginComponent implements OnInit {
  year:any;
  constructor() { }

  ngOnInit() {
    this.year =new Date().getFullYear()
  }

}
