import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalstoregeService } from 'src/app/service/localstorege.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Regex } from 'src/app/_constants/others.constants';
import { ApiCommonService } from 'src/app/service/api-common.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  constructor(
    private router:Router,
    private localStore:LocalstoregeService,
    public apiservice:ApiCommonService,
    private toastr: ToastrService
  ) { }
  public forgetPassForm: FormGroup;
  public invalidforgetPassForm: boolean = false;
  showLoader:boolean = false;

  ngOnInit() {
    this.forgetPassForm = new FormGroup({
      email: new FormControl('', [Validators.required, this.emptyValidator.bind(this), Validators.pattern(Regex.email)]),
    });
  }
  private emptyValidator(control: any) {
    if (this.invalidforgetPassForm) {
      if (control.value.trim() == '') {
        return { empty: true };
      }
      return null;
    } else {
      return null;
    }
  }
  forgetPassFormSubmit(forms:FormGroup){
    if (forms.valid) {
      this.invalidforgetPassForm = false;
      this.doForgotApi(forms);
      // this.gotoURL('reset-password');
    } else {
      this.invalidforgetPassForm = true;
    }
    console.log("forgetPassFormSubmit",forms,this.forgetPassForm);
  }

  doForgotApi(forms: FormGroup) {
    this.showLoader = true;
    let param = { email: forms.value.email};
    this.apiservice.forgotPass(param).then(res => {
      this.showLoader = false;

      if(res.success){
        this.toastr.success(res.message, 'Success', {
          timeOut: 3000,
        });
        this.forgetPassForm.reset();
        this.gotoURL('');
      }else{
        this.toastr.error(res.message,'Error', {
          timeOut: 3000,
        });
      }
    }).catch(err => {
      this.showLoader = false;

    });
  }
  gotoURL(str:string){
    this.router.navigate([str]);
  }
}
