import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { ApiCommonService } from 'src/app/service/api-common.service';
import { CommonService } from 'src/app/service/common.service';
import { LocalstoregeService } from 'src/app/service/localstorege.service';
import { DatePipe } from '@angular/common';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { environment } from '../../../environments/environment'
import { DeviceDetectorService } from 'ngx-device-detector';
import { ClipboardService } from 'ngx-clipboard';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-record-listing',
  templateUrl: './record-listing.component.html',
  styleUrls: ['./record-listing.component.css']
})
export class RecordListingComponent implements OnInit {
  modalRef: BsModalRef;
  deviceInfo:any = null;
  iOS:any = ['IOS','iOS','ios','Mac','MAC','mac'];
  isMacFlag:boolean = false;
  constructor(    
    private datePipe: DatePipe,
    private router:Router,
    private localStore:LocalstoregeService,
    public apiservice:ApiCommonService,
    private toastr: ToastrService,
    private commonservice:CommonService,
    private deviceService: DeviceDetectorService,
    private _clipboardService: ClipboardService,
    private modalService: BsModalService
    ) { 

      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.commonservice.currentOnlinecheck.subscribe(res=>{
        console.log('currentLogincheck',res);
        if(res.status == "ONLINE"){
          this.processToSync();
        }
      });
    }
    showBack:boolean = true;
    isBackConfirm:boolean =false;
    colorTheme = 'theme-red';
    bsConfig: Partial<BsDatepickerConfig>;
    showLoader:boolean = false;
    perPage: number = environment.perPagePagination;
    totalItems: number = 0;
    showBoundaryLinks: boolean = true;
    public current_page:number = 1;
    type:boolean = true;
    isPush:boolean = true;
  typeOfButton:any = [
    {'name':'Sync in progress','className':'badge badge-pill badge-warning text-white'},
    {'name':'Submitted','className':'badge badge-pill badge-info'},
    {'name':'Resubmitted','className':'badge badge-pill badge-danger'},
  ];

  fromDate:any = '';
  toDate:any = '';
  order:any = 'desc';
  showToast:boolean = false;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  processToSync(){
    let localData = this.localStore.getItem('listingData')?JSON.parse(this.localStore.getItem('listingData')):'';
    if(localData.length > 0){
      Swal.fire({
        title: 'You are online',
        text: "Do you want to sync data?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText:'No'
      }).then((result) => {
        if (result.value) {
          this.goForSyncApi(localData);
        }
      })
    }
  }
  copyText(val: string){
    let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      return document.execCommand('copy');
      document.body.removeChild(selBox);


    }
  goForSyncApi(localData:any){
    console.log('goForSyncApi',localData);
      this.apiservice.bulkUpload(localData).then(res => {
        if(res.success){
            this.toastr.success(res.message,'', {
                timeOut: 3000,
              });
        this.localStore.removeItem('listingData');
        this.reset();
        }else{
          this.toastr.error(res.message,'Error', {
            timeOut: 3000,
          });
        }
      }).catch(err => {
      });
  }
  onScroll() {
    this.current_page = this.current_page+ 1;  
    this.isPush = false;
    this.loadData();
  }
  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }
  getClass(str:any){
    let buttonClass = this.typeOfButton.filter(function (n) {
      return n.name == str;
    });
    if(buttonClass.length > 0){
      return buttonClass[0].className;
    }
  }
  listingData:any = [];
  listDemogroup:any = [
    {
      "time":'2019-02-05 16:52:00',
      "name":'Greenland Realtors',
      "status":'Submitted'
    },
    {
      "time":'2019-02-05 18:42:00',
      "name":'Highland Park',
      "status":'Sync in progress'
    },
    {
      "time":'2019-02-05 16:48:00',
      "name":'One Acre land Services',
      "status":'Resubmitted'
    },
    {
      "time":'2019-02-05 16:42:00',
      "name":'Greenland Realtors',
      "status":'Submitted'
    },
    {
      "time":'2019-02-05 16:42:00',
      "name":'Tata Housing Limited',
      "status":'Submitted'
    },
    {
      "time":'2019-02-05 00:00:00',
      "name":'Green Park',
      "status":'Sync in progress'
    }
  ]
  ngOnInit() {
    this.showToast = false;
    this.loadData();

    if(this.iOS.includes(this.deviceInfo.os)){
      this.isMacFlag = true;
    }
    console.log('isMacFal',this.isMacFlag,this.deviceInfo);
  }
  loadData(){
    this.showLoader = true;
   let param = {
      fromDate:this.transformDate(this.fromDate),
      toDate:this.transformDate(this.toDate),
      order:this.order,
      page:this.current_page,
      perpage:this.perPage
    }
    this.apiservice.getLandList(param).then(res => {
      this.showLoader = false;
      if(res.success){
        this.totalItems = res.data.landlist_count;
        if(this.isPush){
          this.listingData = res.data.land_list;
        }else{
          if (res.data.land_list != undefined) {  
            res.data.land_list.forEach(item => {  
              this.listingData.push(item); 
            });  
          } 
        }
 
        this.loadlocalData();
        if(this.showToast){
          this.showToast = false;
          this.toastr.success(res.message, 'Success', {
            timeOut: 3000,
          });
        }
      }else{
        this.loadlocalData();
        if(this.showToast){
          this.showToast = false;
          this.toastr.error(res.message,'Error', {
            timeOut: 3000,
          });
        }
      }
    }).catch(err => {
      this.showLoader = false;
      this.loadlocalData();
    });
  }

  loadlocalData(){
    let localData = this.localStore.getItem('listingData')?JSON.parse(this.localStore.getItem('listingData')):'';
    let myArray = this.listingData;
    if(localData.length > 0){
      localData.forEach((e,i) => {
        var loData = _.filter(myArray, function(n) {
          if (n.id == e.dynamic_id) return n;
      });
      if(loData.length == 0){
        this.listingData.unshift({
          id:e.dynamic_id,
          status: e.status,
          titleCompany: e.titleCompany,
          titleOfficer: e.titleOfficer,
          updated_at: e.updated_at,
        });
      }
      });
    }
  }
  goForEdit(item:any){
    let isLogIn = this.localStore.getItem('status')?this.localStore.getItem('status'):'';
    console.log('goForEdit',item,isLogIn);
    console.log('details',item.id.toString().length);
    if(isLogIn == 'ONLINE'){
      this.router.navigate(['edit-report',item.id]);
    }else{
      if(item.id.toString().length == 13){
        this.router.navigate(['edit-report',item.id]);
      }else{
        Swal.fire({
          title: 'You are offline',
          text: "Please connect to network in order to view / edit synchronized reports",
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Okay',
          cancelButtonText:'No'
        })
      }
    }
  }
  gotoURL(str:string){
    console.log('str==',str);
    if(str){
      this.router.navigate([str]);
    }
  }
  reset(){
    this.fromDate = '';
    this.toDate = '';
    this.order = 'desc';
    this.current_page = 1;
    this.listingData = [];
    this.loadData();
  }

  dosort(){
    if(this.order == 'asc'){
      this.order = 'desc';
    }else{
      this.order = 'asc';
    }
    this.current_page = 1;
    this.listingData = [];
    this.loadData();
  }
  pageChanged(event: PageChangedEvent) {
    const startItem = (event.page - 1) * this.perPage;
    const endItem = event.page * this.perPage;
    this.current_page = event.page;
    console.log('startItem',startItem,'endItem',endItem,'event',event,'current_page',this.current_page);
    this.loadData();
  }

  copyURLText:string = '';
  downloadPDF(item:any){
    
    let isLogIn = this.localStore.getItem('status')?this.localStore.getItem('status'):'';
    console.log('go for the downloadPDF',item);
    if(isLogIn == 'ONLINE'){
      if(item.id.toString().length == 13){
        // this.router.navigate(['edit-report',item.id]);
        console.log('go for the downloadPDF');
        Swal.fire({
          title: 'You are offline',
          text: "You can't export unsynchronized reports",
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Okay',
          cancelButtonText:'No'
        })
      }else{
        this.showLoader = true;
        if(this.isMacFlag){
          this._clipboardService.destroy();
        }
        this.apiservice.exportpdf({id:item.id}).then(res => {
          this.showLoader = false;
          if(res.success){
                if(res.data && res.data.url && res.data.url_normal && res.data.static_url){
                  if(this.isMacFlag){
                    // window.open(res.data.url);
                    // this._clipboardService.copyFromContent(res.data.url);
                    // this.localStore.setItem('pdf_Data',JSON.stringify(res.data));
                    // this.router.navigate(['pdf']);
                    // return;
                    this.copyURLText = res.data.url;
                    document.getElementById('openModal').click();
                    /*
                    let returnType =  this.copyText(res.data.static_url);//this._clipboardService.copyFromContent(res.data.url);
                    console.debug(returnType);
                    
                    if(returnType){
                      this.toastr.success('Please paste the copied link in your browser to download the pdf','', {
                        timeOut: 3000,
                      });
                    }else{
                      this.toastr.error('Your device is not support to copy clipboard new','', {
                        timeOut: 3000,
                      });
                    }
                    */
                    // this.copyText(res.data.static_url);

                  }else{
                    this.toastr.success(res.message,'', {
                      timeOut: 3000,
                    });
                    //window.open(res.data.url_normal);
                    // console.log();
                    // this.localStore.setItem('pdf_Data',JSON.stringify(res.data));
                    // this.router.navigate(['pdf']);
                    // return;
                    window.open(res.data.url);
                  }
                  
                }
          }else{
            this.toastr.error(res.message,'Error', {
              timeOut: 3000,
            });
          }
        }).catch(err => {
          this.showLoader = false;
        });
      }
    }else{
      Swal.fire({
        title: 'You are offline',
        text: "Please connect to network in order to export reports",
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Okay',
        cancelButtonText:'No'
      })
    }

  }
  
  doSearch(){
    this.current_page = 1;
    this.listingData = [];
    this.loadData();
  }
}
