import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LocalstoregeService } from 'src/app/service/localstorege.service';
import { environment } from '../../../environments/environment';
import { ApiCommonService } from 'src/app/service/api-common.service';
import { ToastrService } from 'ngx-toastr';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { Regex } from 'src/app/_constants/others.constants';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { CommonService } from 'src/app/service/common.service';
@Component({
    selector: 'app-form-add',
    templateUrl: './form-add.component.html',
    styleUrls: ['./form-add.component.css']
})
export class FormAddComponent implements OnInit {
    id: any;
    workOrderData: any = {};
    titleOfficerId: number;
    constructor(
        private datePipe: DatePipe,
        private router: Router,
        private localStore: LocalstoregeService,
        public apiservice: ApiCommonService,
        private toastr: ToastrService,
        private commonservice: CommonService,
        private ref: ChangeDetectorRef,
        private route: ActivatedRoute,
    ) {
        this.commonservice.currentOnlinecheck.subscribe(res => {
            if (res && res.status) {
                this.netstatus = res.status;
            }
        });
        this.id = this.route.snapshot.params.id;
    }

    onFlyImageArray: any = [];
    showBack: boolean = true;
    isBackConfirm: boolean = true;
    showLoader: boolean = false;
    colorTheme = 'theme-red';
    bsConfig: Partial<BsDatepickerConfig> = { showWeekNumbers: false };
    titleCompany: any = '';
    titleOfficer: any = '';
    titleCompanyErroText: any = '';
    titleOfficerErroText: any = '';
    finalForm: any = [];
    image_text: any = '';
    image_file: any = [];
    imageDetailsArray: any = [{
        text: "",
        file: []
    }];
    public totalImageSize: any = 0;
    imageDetails: any = {
        text: "",
        file: Array()
    }
    netstatus: any = '';
    staticImageCharacter: number = environment.staticImageCharacter;
    ngOnInit() {
        this.netstatus = this.localStore.getItem('status');
        console.log('netstatus', this.netstatus);
        this.demoForm = JSON.parse(this.demoForm).data;
        let valueLoad = this.localStore.getItem('formValue') ? JSON.parse(this.localStore.getItem('formValue')) : '';
        if (valueLoad) {
            this.demoForm = valueLoad;
            valueLoad.fields.forEach((e, i) => {
                if (e.field_type == "date") {
                    this.demoForm.fields[i].value = new Date(e.value);
                }
                console.log('valueLoad', e.field_type);
            });
        }
        this.showLoader = true;
        this.getWorkOrderDetails()
    }

    //Call Api for get workorderDetails for predefineded value [Title Company,Title Officer,Title Order Number,APN]
    getWorkOrderDetails() {
        console.log('id', this.id);
        let params = {
            list: 1,
            // status: 2,
            id: this.id
        }
        this.apiservice.getWorkOrderListWithDetails(params).then(res => {
            console.log('res==>', res)
            if (res.success) {
                this.workOrderData = res.data && res.data.length && res.data[0] && res.data[0].data && res.data[0].data.length && res.data[0].data[0] ? res.data[0].data[0] : null;
                this.getFromData()
            } else {
                // if(this.showToast){
                //   this.showToast = false;
                //   this.toastr.error(res.message,'Error', {
                //     timeOut: 3000,
                //   });
                // }
            }
        }).catch(err => {
            this.showLoader = false;

        });
    }
    /*End of workworderdetails API call*/

    getFromData() {
        this.apiservice.getFormDetails().then(res => {
            this.showLoader = false;
            if (res.success) {
                this.finalForm = res.data;
                //process predefined vale 
                console.log('this.workOrderData eitai lgbe ', this.workOrderData)
                this.titleCompany = this.workOrderData && this.workOrderData.company ? this.workOrderData.company : null;
                this.titleOfficer = this.workOrderData && this.workOrderData.title_officer_details && this.workOrderData.title_officer_details.name ? this.workOrderData.title_officer_details.name : this.workOrderData && this.workOrderData.created_by_details && this.workOrderData.created_by_details.name ? this.workOrderData.created_by_details.name : null;
                this.titleOfficerId = this.workOrderData && this.workOrderData.title_officer_details && this.workOrderData.title_officer_details.id ? this.workOrderData.title_officer_details.id : this.workOrderData && this.workOrderData.created_by_details && this.workOrderData.created_by_details.id ? this.workOrderData.created_by_details.id : null;
                for (var i = 0; i < this.finalForm.fields.length; i++) {

                    if (this.finalForm.fields[i].field_id == 1 && this.finalForm.fields[i].field_label == "Title Order Number:") {
                        // console.log('inside condition');
                        this.finalForm.fields[i]['value'] = this.workOrderData && this.workOrderData.order_number ? this.workOrderData.order_number : null;
                        this.finalForm.fields[i]['readonly'] = 1
                    }
                    if (this.finalForm.fields[i].field_id == 2 && this.finalForm.fields[i].field_label == "APN:") {
                        // console.log('inside condition');
                        this.finalForm.fields[i]['value'] = this.workOrderData && this.workOrderData.apn ? this.workOrderData.apn : null;
                        this.finalForm.fields[i]['readonly'] = 1
                    }
                    // if (this.finalForm.fields[i].field_id == 10 && this.finalForm.fields[i].field_label == "City, State:") {
                    //     this.finalForm.fields[i]['value'] = this.workOrderData && this.workOrderData.city_details && this.workOrderData.city_details.city_name ? this.workOrderData.city_details.city_name + ',' + this.workOrderData.state_details.state_name : null;
                    //     this.finalForm.fields[i]['readonly'] = 1
                    // }
                    // if (this.finalForm.fields[i].field_id == 11 && this.finalForm.fields[i].field_label == "County:") {
                    //     this.finalForm.fields[i]['value'] = this.workOrderData && this.workOrderData.country ? this.workOrderData.country : null;
                    //     this.finalForm.fields[i]['readonly'] = 1
                    // }

                }
                console.log('after generated new data ', this.finalForm.fields)

            } else {
                this.toastr.error(res.message, 'Error', {
                    timeOut: 3000,
                });
            }
        }).catch(err => {
            this.showLoader = false;
        });
    }

    saveForm(formData, formStatus) {

        console.log('formStatus', formStatus)

        let empty = new RegExp(Regex.empty);
        let email = new RegExp(Regex.email);
        let newPhone = new RegExp(Regex.newPhone);
        let isRequiredCounter = 0
        let isRequiredCounterArr = [];

        formData.fields.forEach((e, i) => {
            if (e.required == 1) {

                if (typeof e.value == "undefined" || e.value == '' || e.value == null) {

                    isRequiredCounter = isRequiredCounter + 1;
                    this.finalForm.fields[i]['showError'] = true;
                    // this.toastr.error('',e.field_label+" can't be blank", {
                    //     timeOut: 3000,
                    //   });
                } else {
                    this.finalForm.fields[i]['showError'] = false;
                }
            }
            // if(e.max_length !=''){
            //     this.finalForm.fields[i]['max_length_count'] = e.max_length;
            // }else{
            this.finalForm.fields[i]['max_length_count'] = '';
            // }
        });

        let imgErrorCountNew: any = 0;
        if (this.netstatus == 'ONLINE') {
            console.log('imageDetailsArray', this.imageDetailsArray);

            this.imageDetailsArray.forEach((e, i) => {
                if (e.file.length > 0) {
                    for (let index = 0; index < e.file.length; index++) {
                        let txt = document.getElementById('text' + i)['value'].trim().replace(/\n/g, " ").replace(/"/g, '\''); //str.trim()
                        // if(!empty.test(txt)){    
                        if (txt.trim().length < 1) {
                            imgErrorCountNew = imgErrorCountNew + 1;
                            this.imageDetailsArray[i]['error'] = "Image heading is required";
                        } else {
                            this.imageDetailsArray[i]['error'] = "";
                        }
                    }
                } else {
                    this.imageDetailsArray[i]['error'] = "";
                }
            });
        }

        // if(!empty.test(this.titleCompany)){    
        if (this.titleCompany == null || this.titleCompany.trim().length < 1) {
            this.titleCompanyErroText = "Company title is required";
        } else {
            this.titleCompanyErroText = "";
        }
        // if(!empty.test(this.titleOfficer)){
        if (this.titleOfficer == null || this.titleOfficer.trim().length < 1) {
            this.titleOfficerErroText = "Officer title is required";
        } else {
            this.titleOfficerErroText = "";
        }
        if (isRequiredCounter > 0 || this.titleOfficerErroText != '' || this.titleCompanyErroText != '' || imgErrorCountNew > 0) {
            console.log('isRequiredCounter', isRequiredCounter, isRequiredCounterArr);
        } else {
            formData['titleCompany'] = this.titleCompany;
            formData['titleOfficer'] = this.titleOfficer;
            formData['stand_alone'] = this.id ? 0 : 1;
            formData['order_id'] = this.id;
            formData['is_published'] = formStatus == 'draft' ? 0 : 1;
            formData['title_off_id'] = this.titleOfficerId;

            console.log('formData', formData);

            this.saveApiWork(formData);
        }

    }


    saveApiWork(formData: any) {
        let netStatus = this.localStore.getItem('status') ? this.localStore.getItem('status') : '';
        this.showLoader = true;
        if (netStatus == 'ONLINE') {
            formData['status'] = 'Submitted';


            /*
            this.apiservice.formSave(formData).then(res => {
                this.showLoader = false;
                if(res.success){
                    this.toastr.success(res.message,'', {
                        timeOut: 3000,
                      });
                this.router.navigate(['report-list']);
                }else{
                  this.toastr.error(res.message,'Error', {
                    timeOut: 3000,
                  });
                }
              }).catch(err => {
                this.showLoader = false;
              });
            */
            let imageText: any = [];
            this.imageDetailsArray.forEach((e, i) => {
                let val = document.getElementById('text' + i)['value'].trim().replace(/\n/g, " ").replace(/"/g, '\'');
                let count = imageText.filter(x => x == val).length;
                if (count > 0) {
                    this.imageDetailsArray[i]['error'] = "Image heading can't be same"
                }
                imageText.push(val);
            });
            if (this.imageDetailsArray.filter(x => x.error != '').length > 0) {
                this.showLoader = false;
            } else {
                this.fileUpload(formData);
            }
        } else if (netStatus == 'OFFLINE') {
            formData['dynamic_id'] = new Date().getTime();
            formData['updated_at'] = new Date().getTime();
            formData['status'] = 'Sync in progress';
            let listingData = this.localStore.getItem('listingData') ? JSON.parse(this.localStore.getItem('listingData')) : '';

            let valueReturn = this.localStore.localStorageSpace();
            let crForm = this.localStore.getBinarySize(JSON.stringify([formData]));

            if (valueReturn && valueReturn.remainSpace > 1024) {
                if (listingData) {
                    this.showLoader = false;
                    listingData.push(formData);
                    this.localStore.setItem('listingData', JSON.stringify(listingData));
                    this.router.navigate(['report-list']);
                    this.toastr.success('Data stored successfully but not synchronize', '', {
                        timeOut: 3000,
                    });
                } else {
                    this.showLoader = false;
                    this.localStore.setItem('listingData', JSON.stringify([formData]));
                    this.router.navigate(['report-list']);
                    this.toastr.success('Data stored successfully but not synchronize', '', {
                        timeOut: 3000,
                    });
                }
            } else {
                this.toastr.error("", 'Your browser memory size has exceeded. Please sync the data going online and try again.', {
                    timeOut: 3000,
                });
                this.showLoader = false;
            }

        }
    }

    addImageFileClick() {
        document.getElementById('image_file').click();
    }
    image_file_static: any = [];
    deleteActionStatic() {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                this.image_file_static = [];
                this.image_file = [];
            }
        });
    }
    onImageFileChanged(event) {
        let textShow = 'Upload';
        switch (event.target.files.length) {
            case 0:
                textShow = 'Upload s';
                break;
            case 1:
                textShow = event.target.files.length + ' file';
                break;
            default:
                textShow = event.target.files.length + ' files';
                break;
        }
        // document.getElementById('span_image_file').innerHTML = textShow;

        // this.image_file = event.target.files;
        if (event.target.files.length > 0) {
            this.image_file.push(event.target.files[0]);

            var reader = new FileReader();
            reader.onload = (event: ProgressEvent) => {
                // this.image_file_static.push({"bs64":(<FileReader>event.target).result,'name':name});
                this.image_file_static = [{ "bs64": (<FileReader>event.target).result, 'name': name }];
            }
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    onFileChanged(event, j) {
        let currentFileSize: any;
        for (let i = 0; i < event.target.files.length; i++) {
            currentFileSize = ((event.target.files[i]['size'] / 1024) / 1024);
            this.totalImageSize = this.totalImageSize + currentFileSize;
        }
        if (this.totalImageSize < environment.maxFileLimit) {

            // this.imageDetailsArray[j].file = event.target.files;
            console.log('onFileChanged', event.target.files[0]);
            const param = new FormData();
            const user_data: string = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : '';
            if (user_data && user_data['user_id']) {
                param.append('user_id', user_data['user_id']);
            }
            param.append('image', event.target.files[0]);
            let imageData: any = [];
            this.showLoader = true;
            this.apiservice.singleUpload(param).then(res => {
                this.showLoader = false;

                if (res['success']) {

                    let textShow = 'Upload';
                    switch (event.target.files.length) {
                        case 0:
                            textShow = 'Upload';
                            break;
                        case 1:
                            textShow = event.target.files.length + ' file';
                            break;
                        default:
                            textShow = event.target.files.length + ' files';
                            break;
                    }
                    document.getElementById('span-' + j).innerHTML = textShow;

                    this.toastr.success(res['message'], '', {
                        timeOut: 3000,
                    });
                    imageData = res['data'];
                    this.onFlyImageArray[j] = Array();
                    for (let i = 0; i < event.target.files.length; i++) {
                        this.imageDetailsArray[j].file.push(event.target.files[i]);
                        this.imageDetailsArray[j].img = imageData && imageData.name ? imageData.name : '';
                        let name = event.target.files[i]['name'];
                        var reader = new FileReader();
                        reader.onload = (event: ProgressEvent) => {
                            this.onFlyImageArray[j].push({ "bs64": (<FileReader>event.target).result, 'name': name });
                        }
                        reader.readAsDataURL(event.target.files[i]);
                    }
                    // this.router.navigate(['report-list']);
                } else {
                    this.toastr.error(res['message'], 'Error', {
                        timeOut: 3000,
                    });
                }
            }).catch(err => {
                this.showLoader = false;
                if (err.status == 500) {
                    this.toastr.error("", err.statusText, {
                        timeOut: 3000,
                    });
                }
                else if (err.status == 400) {
                    this.toastr.error("", err.error.message, {
                        timeOut: 3000,
                    });
                }
                else if (err.status == 401) {
                    this.toastr.error("", err.error.message, {
                        timeOut: 3000,
                    });
                    this.localStore.removeItem('user_data');
                    this.localStore.removeItem('isLoggedIn');
                    this.router.navigate(['']);
                }
            });







        } else {
            this.totalImageSize = this.totalImageSize - currentFileSize;
            this.toastr.error("", 'Maximum image size exceeded', {
                timeOut: 3000,
            });
        }

    }
    addFileClick(j) {
        let nameFile = 'file' + j;
        document.getElementById(nameFile).click();
    }
    deleteAction(k: number, j: number) {
        console.log('deleteAction', this.imageDetailsArray[j].img);

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {



                this.showLoader = true;

                this.apiservice.singleDelete({ name: this.imageDetailsArray[j].img }).then(res => {
                    this.showLoader = false;
                    if (res['success']) {
                        this.toastr.success(res['message'], '', {
                            timeOut: 3000,
                        });
                        for (let q = 0; q < this.imageDetailsArray[j]['file'].length; q++) {
                            if (this.imageDetailsArray[j]['file'][q]['name'] == this.onFlyImageArray[j][k]['name']) {
                                let rtn = this.imageDetailsArray[j]['file'].splice(q, 1);
                                if (rtn) {
                                    this.onFlyImageArray[j].splice(k, 1);
                                    /********/
                                    let textId = 'span-' + j;
                                    let textShow = '';
                                    switch (this.imageDetailsArray[j]['file'].length) {
                                        case 0:
                                            textShow = 'Upload';
                                            break;
                                        case 1:
                                            textShow = this.imageDetailsArray[j]['file'].length + ' file';
                                            break;
                                        default:
                                            textShow = this.imageDetailsArray[j]['file'].length + ' files';
                                            break;
                                    }
                                    document.getElementById(textId).innerHTML = textShow;
                                    /*********/
                                }
                            }
                        }
                    }
                }).catch(err => {
                    this.showLoader = false;
                    if (err.status == 500) {
                        this.toastr.error("", err.statusText, {
                            timeOut: 3000,
                        });
                    } else if (err.status == 401) {
                        this.toastr.error("", err.error.message, {
                            timeOut: 3000,
                        });
                    }
                });
            }
        });
    }

    addMoreClick() {

        this.imageDetailsArray.push({
            text: "",
            file: []
        }
        );

        this.ref.detectChanges();
    }
    onKeyDetailsText(event, i: number) {
        let contSrt = document.getElementById('text' + i)['value'].length;
        this.imageDetailsArray[i]['count'] = 300 - contSrt;
    }
    public image_text_count: any;
    onKeyDetailsTextOne(event) {

        this.image_text_count = this.staticImageCharacter - this.image_text.length;
    }

    onKeyFormText(event, i: number) {
        if (this.finalForm.fields[i].max_length && this.finalForm.fields[i].max_length != '') {
            this.finalForm.fields[i].max_length_count = this.finalForm.fields[i].max_length - this.finalForm.fields[i].value.length;
        }
    }
    transformDate(date) {
        return this.datePipe.transform(date, 'dd-MMM-yyyy');
    }
    fileUpload(formData) {
        //console.log('this.image_file',this.image_file[0]);
        var objIndex = formData.fields.findIndex((obj => obj.field_type == "date"));
        formData.fields[objIndex]['value'] = formData.fields[objIndex]['value'] ? this.transformDate(formData.fields[objIndex]['value']) : '';

        console.log('sdfsdf', formData.fields);



        formData['status'] = 'Submitted';
        formData['titleCompany'] = this.titleCompany;
        formData['titleOfficer'] = this.titleOfficer;
        formData['stand_alone'] = this.id ? 0 : 1;
        const param = new FormData();
        let imageDetailsText: any = [];

        this.imageDetailsArray.forEach((e, i) => {
            if (e.file.length > 0) {
                for (let index = 0; index < e.file.length; index++) {
                    let txt = document.getElementById('text' + i)['value'].trim().replace(/\n/g, " ").replace(/"/g, '\'');
                    // if(imageDetailsText.includes(txt)){
                    //     txt = txt+'('+(imageDetailsText.filter(x => x==txt).length+1)+')'
                    //     imageDetailsText.push(txt);
                    // }else{
                    imageDetailsText.push(txt);
                    // }

                    let fieldsName = 'imageArray[' + txt + ']';
                    // let fieldsName = 'imageArray[' + txt + '--' + index + ']';
                    // let fieldsName = 'imageArray['+txt+'--'+index+'--'+this.rand()+']';
                    // param.append(fieldsName, e.file[index]);
                    // console.log(fieldsName,e.img);

                    param.append(fieldsName, e.img);
                    param.append(e.img, txt);

                }
            }
        });

        param.append('static_image', this.image_file.length > 0 ? this.image_file[0] : '');
        param.append('static_image_text', this.image_text);

        param.append('imageArrayText', JSON.stringify([... new Set(imageDetailsText)]));
        param.append('imageArrayAllText', JSON.stringify(imageDetailsText));
        param.append('fields', JSON.stringify(formData.fields));
        param.append('status', formData.status);
        param.append('titleCompany', formData.titleCompany);
        param.append('titleOfficer', formData.titleOfficer);
        param.append('stand_alone', formData.stand_alone);
        param.append('is_published', formData.is_published);
        param.append('order_id', formData.order_id);
        param.append('title_off_id', formData.title_off_id);


        const user_data: string = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : '';
        if (user_data && user_data['user_id']) {
            param.append('user_id', user_data['user_id']);
        }

        console.log('image_file', this.image_file);
        console.log('image_file', this.image_file);



        this.apiservice.formSaveWithFile(param).then(res => {
            this.showLoader = false;
            if (res['success']) {
                this.toastr.success(res['message'], '', {
                    timeOut: 3000,
                });
                this.router.navigate(['work-order-pending']);
            } else {
                this.toastr.error(res['message'], 'Error', {
                    timeOut: 3000,
                });
            }
        }).catch(err => {
            this.showLoader = false;
            if (err.status == 500) {
                this.toastr.error("", err.statusText, {
                    timeOut: 3000,
                });
            } else if (err.status == 401) {
                this.toastr.error("", err.error.message, {
                    timeOut: 3000,
                });
                this.localStore.removeItem('user_data');
                this.localStore.removeItem('isLoggedIn');
                this.router.navigate(['']);
            }
        });

    }
    rand() {
        return Math.random().toString(36).substring(7);
    }
    removeMoreClick(j) {
        if (this.onFlyImageArray.length > 0 && this.onFlyImageArray[j] && this.onFlyImageArray[j].length > 0 && this.onFlyImageArray[j][0] && this.onFlyImageArray[j][0]['name'] && this.onFlyImageArray[j][0]['name'] != '') {
            this.apiservice.singleDelete({ name: this.onFlyImageArray[j][0]['name'] }).then(res => {
                if (res['success']) {
                    this.onFlyImageArray.splice(j, 1);
                    this.toastr.success(res['message'], '', {
                        timeOut: 3000,
                    });
                }
            }).catch(err => {
                if (err.status == 500) {
                    this.toastr.error("", err.statusText, {
                        timeOut: 3000,
                    });
                } else if (err.status == 401) {
                    this.toastr.error("", err.error.message, {
                        timeOut: 3000,
                    });
                }
            });
        } else {
            this.onFlyImageArray.splice(j, 1);
        }

        this.imageDetailsArray.splice(j, 1);
    }
    toggleValue(op: any, j: any, ind: any) {
        let dycls = "custom-checkbox" + j;
        let dyclsVal = document.getElementsByClassName(dycls);
        let vals = [];
        for (var i = 0, n = dyclsVal.length; i < n; i++) {
            if (dyclsVal[i]['checked']) {
                vals.push(this.finalForm.fields[ind].options[i]['value']);
                this.finalForm.fields[ind].options[i]['selected'] = 1;
            } else {
                this.finalForm.fields[ind].options[i]['selected'] = 0;
            }
        }
        this.finalForm.fields[ind].value = vals
    }

    onTextChanged(event, index) {
        console.log('event' + index, event.target.value);
        this.imageDetailsArray[index]['text'] = event.target.value;
    }
    backbut() {
        // window.history.back();
        Swal.fire({
            title: 'Are you sure you want to exit ?',
            text: "All data will be lost",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                window.history.back();
            }
        });
    }
    demoForm: any = `{
    "success": 1,
    "message": "Form found for this user",
    "data": {
        "fields": [
          {
            "field_id": 121,
            "ordering": 0,
            "field_type": "checkbox-group",
            "field_label": "Checkbox Group 1",
            "required": 1,
            "placeholder": "0",
            "options": [
                {
                    "label": "x",
                    "value": "x",
                    "selected": 0
                },
                {
                    "label": "y",
                    "value": "y",
                    "selected": 0
                },
                {
                    "label": "z",
                    "value": "z",
                    "selected": 0
                }
            ]
        },
            {
                "field_id": 21,
                "ordering": 0,
                "field_type": "checkbox-group",
                "field_label": "Checkbox Group",
                "required": 0,
                "placeholder": "0",
                "options": [
                    {
                        "label": "A",
                        "value": "a",
                        "selected": 1
                    },
                    {
                        "label": "B",
                        "value": "b",
                        "selected": 0
                    },
                    {
                        "label": "C",
                        "value": "c",
                        "selected": 0
                    }
                ]
            },
            {
                "field_id": 22,
                "ordering": 1,
                "field_type": "number",
                "field_label": "Number",
                "required": 1,
                "placeholder": "number",
                "options": [],
                "value":"3564"
            },
            {
                "field_id": 23,
                "ordering": 2,
                "field_type": "radio-group",
                "field_label": "Radio Group",
                "required": 1,
                "placeholder": "0",
                "value":"b",
                "options": [
                    {
                        "label": "A",
                        "value": "a",
                        "selected": 1
                    },
                    {
                        "label": "B",
                        "value": "b",
                        "selected": 0
                    },
                    {
                        "label": "C",
                        "value": "c",
                        "selected": 0
                    }
                ]
            },
            {
                "field_id": 24,
                "ordering": 3,
                "field_type": "select",
                "field_label": "Select",
                "required": 1,
                "placeholder": "0",
                "options": [
                    {
                        "label": "A",
                        "value": "a",
                        "selected": 1
                    },
                    {
                        "label": "B",
                        "value": "b",
                        "selected": 0
                    },
                    {
                        "label": "C",
                        "value": "c",
                        "selected": 0
                    }
                ]
            },
            {
                "field_id": 25,
                "ordering": 4,
                "field_type": "text",
                "field_label": "Text Field",
                "required": 1,
                "placeholder": "Text only",
                "options": []
            },
            {
                "field_id": 26,
                "ordering": 5,
                "field_type": "textarea",
                "field_label": "Text Area",
                "required": 0,
                "placeholder": "Text area",
                "options": [],
                "value":"Dhiman sfvgsfdgv"
            },
            {
                "field_id": 26,
                "ordering": 5,
                "field_type": "file",
                "field_label": "Text file",
                "required": 0,
                "placeholder": "Text area",
                "options": []
            },
            {
                "field_id": 26,
                "ordering": 5,
                "field_type": "date",
                "field_label": "Text date",
                "required": 0,
                "placeholder": "Text area",
                "options": []
            }
        ]
    }
}`;
}