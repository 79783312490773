import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RecordListingComponent } from './record-listing/record-listing.component';
import { FormAddComponent } from './form-add/form-add.component';
import { FormEditComponent } from './form-edit/form-edit.component';
import { ShareModule } from '../share/share.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { BsDatepickerModule, PaginationModule,ModalModule } from 'ngx-bootstrap';
import {NgxPaginationModule} from 'ngx-pagination';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TestpdfComponent } from './testpdf/testpdf.component';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { WorkOrderComponent } from './work-order/work-order.component';
import { WorkOrderCompletedComponent } from './work-order-completed/work-order-completed.component';
import { ViewWorkOrderComponent } from './view-work-order/view-work-order.component';
import { MessageComponent } from './message/message.component';
import { InstantmsgComponent } from './instantmsg/instantmsg.component';
import {msgCustomDatePipe} from '../pipes/customPipes';
@NgModule({
  declarations: [LoginComponent, ResetPasswordComponent, RecordListingComponent, FormAddComponent, FormEditComponent, ForgetPasswordComponent, TestpdfComponent, WorkOrderComponent, WorkOrderCompletedComponent, ViewWorkOrderComponent, MessageComponent, InstantmsgComponent,msgCustomDatePipe],
  imports: [
    // PdfViewerModule,
    CommonModule,
    ShareModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PdfJsViewerModule,
    InfiniteScrollModule
  ]
})
export class UserModule { }
