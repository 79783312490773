import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }
  private logincheckSubject = new BehaviorSubject<any>({});
  public currentLogincheck = this.logincheckSubject.asObservable();
  
  setLogincheck(params: any) {
      this.logincheckSubject.next(params);
  }

  private onlinecheckSubject = new BehaviorSubject<any>({});
  public currentOnlinecheck = this.onlinecheckSubject.asObservable();
  
  setOnlinecheck(params: any) {
      this.onlinecheckSubject.next(params);
  }
}
