import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    
} from '@angular/common/http';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LocalstoregeService } from '../service/localstorege.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(
    private toastr: ToastrService,
    private localStore:LocalstoregeService,
    private router: Router
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //const token: string = localStorage.getItem('token');

        const user_data: string = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : '';
        if (user_data && user_data['token']) {
            console.log("HttpConfigInterceptor",user_data['token']);
            
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + user_data['token']) });
        }

        // if (user_data && user_data['user_id']) {

        //     request = request.clone({ headers: request.headers.set('Userid', '123') });
        // }

        // headers = headers.append('Authorization', customAuthParams);

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                    // this.toastr.success('Hello world!', event.body.message);
                    // this.errorDialogService.openDialog(event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log('error--->>>', error);
                let data = {};
                data = {
                    reason: error && error.error && error.error.message ? error.error.message : '',
                    status: error.status
                };
                if(error.status == 401){
                    this.toastr.error("", data['reason']?data['reason']:error, {
                        timeOut: 3000,
                      });
                    this.localStore.removeItem('user_data');
                    this.localStore.removeItem('isLoggedIn');
                    this.router.navigate(['']);
                }
                if(error.status == 0){
                    data['status'] = error.status;
                    data['reason'] = error.statusText;
                }
                if(error.status == 504){
                    data['status'] = error.status;
                    data['reason'] = 'Please check your internet connection and try again.';//error.statusText;
                }
                if(error.status == 413){
                    data['status'] = error.status;
                    data['reason'] = 'Maximum image size exceeded.';//error.statusText; Payload Too Large response.
                }
                if(error.status == 500){
                    data['status'] = error.status;
                    data['reason'] = error.statusText;// Payload Too Large response.
                }
                this.toastr.error("", data['reason'], {
                    timeOut: 3000,
                  });
                return throwError(error);
            }));
    }
}
