import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiCommonService } from 'src/app/service/api-common.service';
@Component({
  selector: 'app-instantmsg',
  templateUrl: './instantmsg.component.html',
  styleUrls: ['./instantmsg.component.css']
})
export class InstantmsgComponent implements OnInit {

  wid: any;
  uid: any;
  frndid: any;
  messageDetails: any = [];
  chatMsg: String = '';
  showLoader: boolean = false;
  public current_page: number = 1;
  showBack:boolean = true;
  isBackConfirm:boolean =false;
  constructor(
    private route: ActivatedRoute,
    public apiservice: ApiCommonService,
  ) {
    this.wid = this.route.snapshot.params.wid;
    this.uid = this.route.snapshot.params.uid;
    this.frndid = this.route.snapshot.params.frndid;
  }

  ngOnInit() {
    console.log('wid', this.wid);
    console.log('uid', this.uid);
    console.log('frndid', this.frndid);
    this.loadChatDetails();
  }

  loadChatDetails() {
    this.showLoader = true;
    let params = {
      me_user: this.uid ? this.uid : null,
      friend_user: this.frndid,
      page:this.current_page,
      order_id:this.wid
    }
    this.apiservice.getParticularFriendChatDetails(params).then(res => {
      console.log('res==>', res)
      this.showLoader = false;
      if (res.success) {
        if(!this.messageDetails.length){
        this.messageDetails = res.data && res.data.length && res.data[0] && res.data[0].data && res.data[0].data.length ? res.data[0].data.reverse() : [];

        console.log('this.messageDetails', this.messageDetails)
        setTimeout(() => {
          var objDiv = document.getElementById("cmessages");
          console.log('scrollHeight', objDiv.scrollHeight)
          objDiv.scrollTop = objDiv.scrollTop + objDiv.scrollHeight;
        }, 200);
        }
        else{
          console.log('unshift here');
          var data = res.data[0].data;
          data.forEach(item => {  
          this.messageDetails.unshift(item);
          })
        }

        console.log('outside this.messageDetails', this.messageDetails)

      } else {
        // if(this.showToast){
        //   this.showToast = false;
        //   this.toastr.error(res.message,'Error', {
        //     timeOut: 3000,
        //   });
        // }
      }
    }).catch(err => {
      this.showLoader = false;

    });
  }
  onScroll(){
    console.log('scrolled up called');
    this.current_page=this.current_page+1;
    this.loadChatDetails()
  }

  sendChat() {
    console.log('sendChat', this.chatMsg);
    this.showLoader = true;
    let params = {
      sent_by: this.uid,
      sent_to: this.frndid,
      order_id: this.wid,
      message: this.chatMsg,
    }
    this.apiservice.saveOrSendMsg(params).then(res => {
      this.showLoader = false;
      if (res.success) {
        this.current_page=1;
        this.messageDetails=[];
        this.loadChatDetails()
        this.chatMsg = '';
      } else {
        // if(this.showToast){
        //   this.showToast = false;
        //   this.toastr.error(res.message,'Error', {
        //     timeOut: 3000,
        //   });
        // }
      }
    }).catch(err => {
      this.showLoader = false;

    });
  }

  onKeypressEvent(e: any){

    // console.log('keypress event',e.target.value);

    if(e.keyCode == 13 && e.shiftKey == false) {
      // console.log('enter key press in text box');
      this.sendChat()
    }
 }

 addImageFileClick() {
  document.getElementById('image_file').click();
}
onImageFileChanged(event) {
  console.log('file upload event ',event.target.files[0]);
  const param = new FormData();
  param.append('sent_to',this.frndid);
  param.append('order_id',this.wid ? this.wid : null);
  param.append('files[0]', event.target.files[0]);
  
  this.showLoader = true;
  this.apiservice.fileSendOverMsg(param).then(res => {
      this.showLoader = false;
      this.current_page=1;
      this.messageDetails=[];
      this.loadChatDetails()

  }).catch(err => {
      this.showLoader = false;
     
  });


 
}



}
